import type { DeepReadonly } from "vue";
import type { FormFilterName } from "@newgenerated/shared/schema";
import type { ContentTypeDiscriminator, FormFilterExtended, UpdateFilter } from "@/components/search/fullSearchStoreTypes";
import type { GaVueComponent } from "@/common/vueUtils";
import { useI18n } from "@/i18n/i18nSetup";
import { Filter } from "@/components/search/Filter";
import { GaButton } from "@/components/general/GaButton";

export function Filters(props: {
  formFilters: DeepReadonly<FormFilterExtended[]>;
  applyFilters: () => void;
  updateFilter: UpdateFilter;
  filtersExtended: boolean;
  toggleFilter: (filterName: FormFilterName) => void;
  contentType: ContentTypeDiscriminator;
  onMultiSelectSearchTermChange: (contentType: ContentTypeDiscriminator, formFilterName: FormFilterName, searchTerm: string) => void;
  toggleMultiSelect: (contentType: ContentTypeDiscriminator, formFilterName: FormFilterName) => void;
}): GaVueComponent {
  const { t } = useI18n();
  return (
    <div class={["collapse", props.filtersExtended ? "show" : ""]}>
      <div>
        <div class="filtering-dropdown">
          <div class="filtering-dropdown__left-col">
            {props.formFilters
              .filter((filter) => filter.priority === "PRIMARY")
              .map((filter) => (
                <Filter
                  filter={filter}
                  updateFilter={(filterType, value) => props.updateFilter(filterType, value, props.contentType)}
                  toggleFilter={props.toggleFilter}
                  onMultiSelectSearchTermChange={(filter, term) => props.onMultiSelectSearchTermChange(props.contentType, filter, term)}
                  toggleMultiSelectSearch={(filter) => props.toggleMultiSelect(props.contentType, filter)}
                />
              ))}
          </div>
          <div class="filtering-dropdown__right-col">
            {props.formFilters
              .filter((filter) => filter.priority === "SECONDARY")
              .map((filter) => (
                <Filter
                  filter={filter}
                  updateFilter={(filterType, value) => props.updateFilter(filterType, value, props.contentType)}
                  toggleFilter={props.toggleFilter}
                  onMultiSelectSearchTermChange={(filter, term) => props.onMultiSelectSearchTermChange(props.contentType, filter, term)}
                  toggleMultiSelectSearch={(filter) => props.toggleMultiSelect(props.contentType, filter)}
                />
              ))}
          </div>
          <div>
            <GaButton onClick={props.applyFilters}>{t("general:button.apply")}</GaButton>
          </div>
        </div>
      </div>
    </div>
  );
}
