import { assert, ensureNonNull } from "@utils/assertion";
import VimeoPlayer from "@vimeo/player";
import { trackProTalkVideoProgress, trackStep2VideoProgress } from "@generated/api/actionableCustomerIxApiControllerApi";
import { registerModule } from "@/Registry";

export function registerVideoProgressTracking(scope: Element): void {
  assert(scope instanceof HTMLElement);
  const iframe = ensureNonNull(scope.tagName === "iframe" ? (scope as HTMLIFrameElement) : scope.querySelector<HTMLIFrameElement>("iframe"));
  const player = new VimeoPlayer(iframe);
  const videoType = scope.dataset.gaActionableVideoProgress;
  assert(videoType === "step2" || videoType === "proTalk");
  const actionableId = parseInt(scope.dataset.gaActionableVideoProgressActionableId ?? "");
  assert(!isNaN(actionableId));
  let staticProgress = -0.1; // Start to log at 0
  const updateProgress = async function (e: VimeoPlayer.TimeEvent): Promise<void> {
    // Report to the server in 10% steps only, to limit server resources
    console.log(e);
    if (e.percent >= staticProgress + 0.1) {
      staticProgress = Math.floor(e.percent * 10) / 10; // Round to 10% steps
      if (videoType === "step2") {
        await trackStep2VideoProgress(actionableId, staticProgress);
      } else {
        await trackProTalkVideoProgress(actionableId, staticProgress);
      }
    }
  };
  player.on("play", (e) => updateProgress(e));
  player.on("timeupdate", (e) => updateProgress(e));
  player.on("ended", (e) => updateProgress(e));
}

registerModule("[data-ga-actionable-video-progress]", registerVideoProgressTracking);
