/*
 * General function that remove all origin-related parameters from the
 */

export const cleanUrl = (href: string): string => {
  const url = new URL(href);
  Array.from(url.searchParams.keys())
    .filter((k) => k.startsWith("o_"))
    .forEach((key) => {
      url.searchParams.delete(key);
    });
  return url.href;
};

document.addEventListener("DOMContentLoaded", () => {
  const newUrl = cleanUrl(window.location.href);
  window.history.replaceState({ path: newUrl }, "", newUrl);
});
