import { registerModule } from "@/Registry";
import { assert } from "@utils/assertion";

const countLines = (element: HTMLElement): number => {
  const divHeight = element.offsetHeight;
  assert(document.defaultView !== null);
  const lineHeight = parseInt(document.defaultView.getComputedStyle(element, null).getPropertyValue("line-height"));
  return divHeight / lineHeight;
};

const countLinesOnElement = (promobox: HTMLElement, selector: string): number => {
  const element = promobox.querySelector<HTMLElement>(selector);
  if (element !== null) {
    return countLines(element);
  }
  return 0;
};

const handleExpandFeature = (promoBox: Element): void => {
  assert(promoBox instanceof HTMLDivElement);
  const titleLines = countLinesOnElement(promoBox, ".promobox-card__title");
  const descriptionLines = countLinesOnElement(promoBox, ".promobox-card__text");
  if (titleLines + descriptionLines > 3 || titleLines === 3) {
    promoBox.classList.add("promobox-card--expandable");
  } else {
    promoBox.classList.remove("promobox-card--expandable");
  }
};

const registerSizeChange = (promobox: Element): void => {
  handleExpandFeature(promobox);
  document.addEventListener("splide-resize", () => {
    handleExpandFeature(promobox);
  });
};

registerModule("[data-ga-promobox]", registerSizeChange);
