import { registerModule } from "@/Registry";

export function registerDropDownChangeEvent(form: Element): void {
  const dropdown = form.querySelector("select");
  if (dropdown != null) {
    dropdown.addEventListener("change", (event) => {
      if (event.target != null && event.target instanceof HTMLSelectElement) {
        const form = event.target.closest("form");
        if (form instanceof HTMLFormElement) {
          form.submit();
        }
      }
    });
  }
}

registerModule("[data-ga-actionable-overview-channeldropdown]", registerDropDownChangeEvent);
