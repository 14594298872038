import type { GaVueComponent } from "@/common/vueUtils";
import { GaFeedback } from "@/components/general/GaFeedback";

export function GaFeedbackDemo(): GaVueComponent {
  const summaryTitle = "How to train dragons";
  return (
    <div>
      <h1 class="mb-4">Feedback Component</h1>
      <div class="mb-4">
        <GaFeedback
          title={"Did you like the summary you just read?"}
          positiveFeedbackText={"Amazing!"}
          negativeFeedbackText={"Not so much"}
          changeHandler={() => console.log("just do nothing")}
          mailTitle={`I just read this summary: ${summaryTitle}`}
          mailBody={"You should definitely read it, check it out now."}
          showMailFeedbackLink={true}
        />
      </div>
      <div class="mb-4">
        <GaFeedback
          title={"How do you like the getAbstract IT team?"}
          positiveFeedbackText={"Cool guys!"}
          negativeFeedbackText={"Total pricks!"}
          changeHandler={() => console.log("just do nothing")}
          mailTitle={""}
          mailBody={""}
          showMailFeedbackLink={false}
        />
      </div>
    </div>
  );
}
