import type { GaVueComponent } from "@/common/vueUtils";
import { ActionableCardMini } from "@/components/actionable/ActionableCardMini";
import type { DeepReadonly } from "vue";
import { useI18n } from "@/i18n/i18nSetup";
import type { ActionableMiniView } from "@generated/model/actionableMiniView";

export function RelatedActionables(props: { relatedActionables: DeepReadonly<ActionableMiniView[]>; questionUuid: string; showExplanation?: boolean; border?: boolean }): GaVueComponent | null {
  const { t } = useI18n();
  if (props.relatedActionables.length === 0) {
    return null;
  }

  return (
    <div class="d-grid gap-3">
      <h4 class="m-0">{t("search:askGa.relatedActionables.title")}</h4>
      {props.showExplanation === true ? <p class="m-0">{t("search:askGa.explanation.actionables")}</p> : null}
      <div class="row row-cols-1 row-cols-md-3 row-gap-3">
        {props.relatedActionables.map((actionable) => (
          <div class="col">
            <ActionableCardMini
              actionable={actionable}
              params={
                new URLSearchParams({
                  o_s: "QA",
                  o_r: props.questionUuid,
                })
              }
              target="_blank"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
