import { assert, assertIsArray, assertIsTypedArrayOf, ensureNonNull, hasProp } from "@utils/assertion";
import { registerModule } from "@/Registry";
import { isNonNullObject } from "@utils/objectUtils";

export type CountryDropdownEntry = {
  value: string;
  label: string;
  countryPhonePrefix: string;
};

function init(scope: Element): void {
  const enterpriseForm = scope.querySelector("[data-enterprise-form-ip-country]");
  const countrySelector = scope.querySelector("[data-enterprise-form-country-selector]");
  const result = scope.querySelector("[data-enterprise-form-phone-field]");

  assert(enterpriseForm !== null && countrySelector !== null && result !== null);

  const countryCodeByIp = enterpriseForm.getAttribute("data-enterprise-form-ip-country");
  const resultInput = ensureNonNull(result.getElementsByTagName("input").item(0));
  // eslint-disable-next-line no-restricted-syntax
  const countryDropdownJson = JSON.parse(ensureNonNull(countrySelector.getAttribute("data-enterprise-form-country-selector")));
  assertIsArray(countryDropdownJson);
  assertIsTypedArrayOf<CountryDropdownEntry>(countryDropdownJson, (value) => {
    return isNonNullObject(value) && hasProp(value, "value") && hasProp(value, "label") && hasProp(value, "countryPhonePrefix");
  });

  if (countryCodeByIp !== null && countryCodeByIp !== "--") {
    const options = ensureNonNull(countrySelector.getElementsByTagName("select").item(0)).options;
    const toSelect = Array.from(options).find((option) => option.value === countryCodeByIp);
    if (toSelect !== undefined) {
      toSelect.selected = true;
      resultInput.value =
        countryDropdownJson.find((e) => {
          return e.value === toSelect.value;
        })?.countryPhonePrefix ?? "";
    }
  }

  countrySelector.addEventListener("input", (e) => {
    assert(e.target instanceof HTMLSelectElement, "input is not of type HTMLSelectElement");
    const selectedOption = ensureNonNull(e.target.selectedOptions.item(0));
    resultInput.value =
      countryDropdownJson.find((e) => {
        return e.value === selectedOption.value;
      })?.countryPhonePrefix ?? "";
  });
}

registerModule("[data-enterprise-form]", init);
