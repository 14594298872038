// WIP: please do not use yet
import type { DeepReadonly } from "vue";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaAlert } from "@/components/general/GaAlert";
import type { Store } from "@/common/storeUtils";
import { delay } from "@utils/asyncUtils";

export type SubmissionState = "SUBMITTING" | "IDLE";

type Props = {
  store: Store<SubmissionState>;
  onSubmit: () => Promise<void>;
  fields: GaVueComponent;
  footer: GaVueComponent;
  errors?: DeepReadonly<string[]>;
};

export function GaFormExperimental(props: Props): GaVueComponent {
  async function onSubmit(e: Event): Promise<void> {
    e.preventDefault();
    while (props.store.get() === "SUBMITTING") {
      // TODO: Or should it just ignore a duplicate submission?
      await delay(100);
    }
    props.store.set("SUBMITTING");
    try {
      await props.onSubmit();
    } catch (e: unknown) {
      // TODO: What to do with the error?
      console.error(e);
    } finally {
      props.store.set("IDLE");
    }
  }

  const errors = props.errors ?? [];
  return (
    <form onSubmit={onSubmit}>
      {props.fields}
      {errors.length > 0 ? (
        <div class="my-3">
          <GaAlert variant="danger">
            <ul style="margin: 0;">
              {errors.map((error) => (
                <li>{error}</li>
              ))}
            </ul>
          </GaAlert>
        </div>
      ) : null}
      {props.footer}
    </form>
  );
}
