import { defineComponent, ref } from "vue";
import { GaFormFieldInputText } from "@/components/form/GaFormFieldInputText";
import { GaButton } from "@/components/general/GaButton";
import { SearchAskGetAbstractComponent } from "@/components/search/SearchAskGetAbstractComponent";
import { STREAMING_SPEED_UP_FACTOR, STREAMING_TOKEN_PER_SEC, WAITING_INTERVAL_IN_MS } from "@/components/ask-getabstract/utils/store";
import { type ComponentSimpleInteractions, simpleBackgroundWorker, type SimpleComponentUiState } from "@/components/ask-getabstract/utils/backgroundWorker";
import { delay } from "@utils/asyncUtils";
import { startTimer } from "@/components/ask-getabstract/utils/animationUtils";
import { createQuestion, getQuestionAnswers } from "@generated/api/askGetabstractControllerApi";
import { renderJsonWithNumbersAsBigint } from "@utils/json";

export const FullSearchAiDemoComponent = defineComponent({
  setup() {
    const input = ref<string>("");
    const interactions = ref<ComponentSimpleInteractions>({ searchTerm: "" });
    const uiState = ref<SimpleComponentUiState>({ kind: "INITIAL" });
    const displayFullAnswer = ref(false);
    void simpleBackgroundWorker(
      {
        streamingTokensPerSec: STREAMING_TOKEN_PER_SEC,
        streamingSpeedUpFactor: STREAMING_SPEED_UP_FACTOR,
        analyticsEventVariant: "qa_demo",
      },
      {
        getInteractions: () => interactions.value,
        updateUi: (newState) => {
          uiState.value = newState;
        },
        initQuestionAnswer: createQuestion,
        getQuestionAnswers: getQuestionAnswers,
        delay: () => delay(WAITING_INTERVAL_IN_MS),
        isAborted: () => false,
        startTimer,
      },
    );

    function updateSearchTerm(newValue: string): void {
      input.value = newValue;
    }

    function triggerSearch(e?: Event): void {
      e?.preventDefault();
      interactions.value = { searchTerm: input.value };
    }

    return () => (
      <>
        <h1 class="mb-4">Full Search AI component demo</h1>
        <div class="row mb-4">
          <div class="col-8">
            <form onSubmit={triggerSearch}>
              <div class="row">
                <div class="col-9">
                  <GaFormFieldInputText value={input.value} onUpdateValue={(newValue) => updateSearchTerm(newValue)} name="searchTerm" />
                </div>
                <div class="col-3">
                  <GaButton>Search</GaButton>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <div style="width: 100%; min-height: 40rem; border: 2px solid lightgrey;padding: 1rem;">
              <h2>Current ui state</h2>
              {renderJsonWithNumbersAsBigint(uiState.value, 2)}
            </div>
          </div>
          <div class="col-4">
            <SearchAskGetAbstractComponent state={uiState.value} displayFullAnswer={displayFullAnswer.value} showMore={() => (displayFullAnswer.value = true)} smallVersion={false} />
          </div>
        </div>
      </>
    );
  },
});
