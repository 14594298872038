import { registerModule } from "@/Registry";

function getTarget(ctx: Document, element: Element): Element | null {
  const target = element.getAttribute("data-bs-target");
  if (target !== null && target !== "#") {
    return ctx.querySelector(target.trim());
  }

  const hrefAttribute = element.getAttribute("href");
  if (hrefAttribute === null || hrefAttribute === "#") {
    throw new Error("Missing or invalid 'data-bs-target' or 'href' attribute.");
  }

  return ctx.querySelector(hrefAttribute.trim());
}

function collapseLabelToggle(element: Element): void {
  // This plugin assumes it is used with the Bootstrap's 'Collapse' component and its events.
  if (element.getAttribute("data-bs-toggle") !== "collapse") {
    return;
  }

  // Bad practice to use the 'document' and not a sensible context,
  // but the underlying Bootstrap component works that way and this should support that plugin without additional restrictions.
  const target = getTarget(document, element);
  const hiddenLabel = element.querySelector<HTMLElement>('[data-ga-collapse-label-toggle-state="hidden"]');
  const shownLabel = element.querySelector<HTMLElement>('[data-ga-collapse-label-toggle-state="shown"]');

  if (target === null || hiddenLabel === null || shownLabel === null) {
    return;
  }

  target.addEventListener("hide.bs.collapse", () => {
    hiddenLabel.removeAttribute("hidden");
    shownLabel.setAttribute("hidden", "true");
  });
  target.addEventListener("show.bs.collapse", () => {
    hiddenLabel.setAttribute("hidden", "true");
    shownLabel.removeAttribute("hidden");
  });
}

registerModule("[data-ga-collapse-label-toggle]", collapseLabelToggle);
