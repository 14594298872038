/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { getHttpClient } from "@utils/httpClient";

/* tslint:disable:no-unused-locals */
// @ts-ignore
import { type PeerInsightFeedbackDashboardView, parsePeerInsightFeedbackDashboardView, renderPeerInsightFeedbackDashboardView } from '../model/peerInsightFeedbackDashboardView';
// @ts-ignore
import { type PeerInsightFeedbackForm, parsePeerInsightFeedbackForm, renderPeerInsightFeedbackForm } from '../model/peerInsightFeedbackForm';
// @ts-ignore
import { type PeerInsightFormNavigation, parsePeerInsightFormNavigation, renderPeerInsightFormNavigation } from '../model/peerInsightFormNavigation';
// @ts-ignore
import type { Page } from "@utils/type/type";


/**
* 
* @summary Get feedbacks for actionable.
* @param actionableId 
*/
export async function getFeedbacks(actionableId: number): Promise<Array<PeerInsightFeedbackDashboardView>> {
        return getHttpClient().request<Array<PeerInsightFeedbackDashboardView>>({
            url: '/gaapi/actionables/{actionableId}/peerinsights'
                .replace('{' + 'actionableId' + '}', encodeURIComponent(String(actionableId))),
            method: 'GET'
        })
        .then(response => {
                return response.data.map(value => parsePeerInsightFeedbackDashboardView(value));
        });
}
/**
* 
* @summary Return the new questionId after navigation.
* @param peerInsightFormNavigation Parameters for the form navigation.
*/
export async function navigateForm(peerInsightFormNavigation: PeerInsightFormNavigation): Promise<number> {
        return getHttpClient().request<number>({
            url: '/gaapi/actionables/peerinsight/navigateForm',
            method: 'POST',
            data: renderPeerInsightFormNavigation(peerInsightFormNavigation)
        })
        .then(response => {
               return response.data;
        });
}
/**
* 
* @summary Submit peer insight form.
* @param peerInsightFeedbackForm Submit peer insight form.
*/
export async function submitPeerInsightForm(peerInsightFeedbackForm: PeerInsightFeedbackForm): Promise<Array<number>> {
        return getHttpClient().request<Array<number>>({
            url: '/gaapi/actionables/peerinsight/submitForm',
            method: 'POST',
            data: renderPeerInsightFeedbackForm(peerInsightFeedbackForm)
        })
        .then(response => {
               return response.data;
        });
}
/**
* 
* @summary Likes or removes the like depending on the current status.
* @param feedbackId 
*/
export async function toggleLike(feedbackId: number): Promise<void> {
        return getHttpClient().request({
            url: '/gaapi/actionables/peerinsight/change-like',
            method: 'POST',
            params: { feedbackId }
        });
}

