import type { DeepReadonly } from "vue";
import { type ChannelPaging, type ContentTypeProps, defaultAmount, type ExtendedContentType, type SearchStatus, type SearchStoreActions, type SummariesPaging } from "@/components/search/fullSearchStoreTypes";
import type { FormFilter } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { useI18n } from "@/i18n/i18nSetup";
import { LoadingComponent } from "@/components/search/LoadingComponent";
import { GaChip } from "@/components/general/GaChip";
import { FilterChip } from "@/components/search/FilterChip";
import { SummaryCard } from "@/components/cards/SummaryCard";
import { GaButton } from "@/components/general/GaButton";
import ChannelCardMini from "@utils/vue-migration/component/channel/ChannelCardMini.vue";
import CustomPageCardMini from "@utils/vue-migration/component/custompage/CustomPageCardMini.vue";
import { ActionableCardMini } from "@/components/actionable/ActionableCardMini";
import { Filters } from "@/components/search/SummarySearchFilters";
import { NoResults } from "@/components/search/NoResults";

function ContentTypeChildren(props: {
  contentType: DeepReadonly<ContentTypeProps>;
  formFilters: DeepReadonly<FormFilter[]>;
  summariesPaging: SummariesPaging;
  loadMoreSummaries: () => void;
  channelPaging: ChannelPaging;
  loadMoreChannels: () => void;
  selected: boolean;
  params: URLSearchParams;
  searchStatus: SearchStatus;
}): GaVueComponent {
  const category = props.contentType;
  const amountToShow = props.selected ? category.items.length : props.contentType.amountToShow;
  const standardColumns = "row row-cols-1 row-cols-md-2 g-4 ";
  const bigColumn = standardColumns + (props.selected ? "row-cols-lg-3" : "");
  const smallColumn = standardColumns + (props.selected ? "row-cols-lg-4" : "row-cols-lg-3");
  const { t } = useI18n();
  switch (category.discriminator) {
    case "SUMMARY":
      return (
        <>
          <div class={bigColumn}>
            {category.items.slice(0, amountToShow).map((result, i) => {
              const params = new URLSearchParams(props.params);
              params.append("o_p", String(i));
              return (
                <div class="col" key={i}>
                  <SummaryCard
                    summary={{
                      ...result,
                      authors: [...result.authors],
                      countriesIncluded: [...result.countriesIncluded],
                      countriesExcluded: [...result.countriesExcluded],
                      tags: [...result.tags],
                    }}
                    params={params}
                  />
                </div>
              );
            })}
          </div>
          {props.summariesPaging.totalCount > category.items.length && props.selected ? (
            <div class="d-flex justify-content-center mt-3">
              <GaButton onClick={() => props.loadMoreSummaries()}>{props.searchStatus === "LOADMORE" ? <div class="spinner-border spinner-border-sm" /> : t("browse:explore.loadMore", ["12"])}</GaButton>
            </div>
          ) : null}
        </>
      );
    case "CHANNEL":
      return (
        <>
          <div class={bigColumn}>
            {category.items.slice(0, amountToShow).map((result, i) => {
              const params = new URLSearchParams(props.params);
              params.append("o_p", String(i));
              return (
                <div class="col" key={i}>
                  <ChannelCardMini channel={{ ...result, visibleLanguages: [...result.visibleLanguages] }} params={params} />
                </div>
              );
            })}
          </div>
          {props.channelPaging.totalCount > category.items.length && props.selected ? (
            <div class="d-flex justify-content-center mt-3">
              <GaButton onClick={() => props.loadMoreChannels()}>{props.searchStatus === "LOADMORE" ? <div class="spinner-border spinner-border-sm" /> : t("browse:explore.loadMore")}</GaButton>
            </div>
          ) : null}
        </>
      );
    case "CUSTOMPAGE":
      return (
        <div class={smallColumn}>
          {category.items.slice(0, amountToShow).map((result, i) => {
            const params = new URLSearchParams(props.params);
            params.append("o_p", String(i));
            return (
              <div class="col" key={i}>
                <CustomPageCardMini customPage={result} params={params} />
              </div>
            );
          })}
        </div>
      );
    case "ACTIONABLE":
      return (
        <div class={bigColumn}>
          {category.items.slice(0, amountToShow).map((result, i) => {
            const params = new URLSearchParams(props.params);
            params.append("o_p", String(i));
            return (
              <div class="col" key={i}>
                <ActionableCardMini actionable={{ actionableId: BigInt(result.actionableId), name: result.name, title: result.title, coverUri: result.coverUri }} params={params} />
              </div>
            );
          })}
        </div>
      );
  }
}

export function ContentType(props: {
  contentType: DeepReadonly<ContentTypeProps>;
  loadMoreSummaries: () => void;
  loadMoreChannels: () => void;
  searchStatus: SearchStatus;
  actions: SearchStoreActions;
  selectedContentType: ExtendedContentType;
  filtersExtended: boolean;
  summariesPaging: SummariesPaging;
  channelPaging: ChannelPaging;
  params: URLSearchParams;
}): GaVueComponent {
  const { t } = useI18n();
  const isSelected = props.selectedContentType === props.contentType.discriminator;
  if (props.searchStatus === "LOADING") {
    return <LoadingComponent large={props.contentType.discriminator === "SUMMARY"} />;
  } else if (props.contentType.items.length > 0 || isSelected) {
    return (
      <div>
        <h2 class="mb-4">{props.contentType.title}</h2>
        {props.contentType.formFilters.length > 0 && isSelected ? (
          <div class="mb-4">
            <div class="d-flex flex-wrap gap-2 mb-3">
              <GaChip icon="ico-equalizer" type={"checkbox"} changeHandler={props.actions.toggleFilters}>
                {t("summary:filtersTitle")}
              </GaChip>
              {props.contentType.formFilters
                .filter((filter) => filter.activeValues.length > 0)
                .map((filter) => (
                  <FilterChip filter={filter} resetFilter={(type) => props.actions.updateFilter(type, [], props.contentType.discriminator)} />
                ))}
            </div>
            <Filters
              formFilters={props.contentType.formFilters}
              applyFilters={props.actions.search}
              updateFilter={props.actions.updateFilter}
              filtersExtended={props.filtersExtended}
              toggleFilter={(filter) => props.actions.toggleSingleFilter(filter, props.contentType.discriminator)}
              contentType={props.contentType.discriminator}
              onMultiSelectSearchTermChange={props.actions.onMultiSelectSearchTermChange}
              toggleMultiSelect={props.actions.toggleMultiSelect}
            />
          </div>
        ) : null}
        {props.contentType.items.length > 0 ? (
          <ContentTypeChildren
            contentType={props.contentType}
            formFilters={props.contentType.formFilters}
            loadMoreSummaries={props.loadMoreSummaries}
            summariesPaging={props.summariesPaging}
            channelPaging={props.channelPaging}
            loadMoreChannels={props.loadMoreChannels}
            selected={isSelected}
            params={props.params}
            searchStatus={props.searchStatus}
          />
        ) : props.selectedContentType !== "ALL" ? (
          <div class="py-3">
            <NoResults resetFilters={() => (props.selectedContentType === "ALL" ? props.actions.resetAllFilters() : props.actions.resetAllFilters(props.selectedContentType))} />
          </div>
        ) : null}
        {props.selectedContentType === "ALL" && props.contentType.items.length > defaultAmount[props.contentType.discriminator] ? (
          <div class="mt-3">
            <a type="button" class="btn btn-alt-primary btn-sm fullSearch__moreResultsButton" onClick={() => props.actions.selectContentType(props.contentType.discriminator)}>
              {props.contentType.formFilters.length > 0 ? t("search:page.results.showMoreFilters") : t("search:page.results.showMore")}
            </a>
          </div>
        ) : null}
      </div>
    );
  }
  return <></>;
}
