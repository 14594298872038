import { ensureNonNull } from "@utils/assertion";
import { NavigationDirection } from "@newgenerated/shared/schema";
import { serialize_NavigationDirection, deserialize_NavigationDirection } from "@newgenerated/shared/mapper";

export { NavigationDirection } from "@newgenerated/shared/schema";
export const parseNavigationDirection = (data: unknown): NavigationDirection => {
  return ensureNonNull(deserialize_NavigationDirection(JSON.stringify(data)));
};
export const renderNavigationDirection = (data: NavigationDirection): any => {
  return JSON.parse(serialize_NavigationDirection(data));
};
