import { ensureNonNull } from "@utils/assertion";
import { type FullSearchResults } from "@newgenerated/shared/schema";
import { serialize_FullSearchResults, deserialize_FullSearchResults } from "@newgenerated/shared/mapper";

export { type FullSearchResults } from "@newgenerated/shared/schema";
export const parseFullSearchResults = (data: unknown): FullSearchResults => {
  return ensureNonNull(deserialize_FullSearchResults(JSON.stringify(data)));
};
export const renderFullSearchResults = (data: FullSearchResults): any => {
  return JSON.parse(serialize_FullSearchResults(data));
};
