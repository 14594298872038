import { ensureNonNull } from "@utils/assertion";
import { type WarmupV2Submission } from "@newgenerated/shared/schema";
import { serialize_WarmupV2Submission, deserialize_WarmupV2Submission } from "@newgenerated/shared/mapper";

export { type WarmupV2Submission } from "@newgenerated/shared/schema";
export const parseWarmupV2Submission = (data: unknown): WarmupV2Submission => {
  return ensureNonNull(deserialize_WarmupV2Submission(JSON.stringify(data)));
};
export const renderWarmupV2Submission = (data: WarmupV2Submission): any => {
  return JSON.parse(serialize_WarmupV2Submission(data));
};
