import type { GaVueComponent } from "@/common/vueUtils";
import { useI18n } from "@/i18n/i18nSetup";
import { getUsedReferencesByDataId } from "@/components/ask-getabstract/utils/answerUtils";
import { GaMarkdownContent, useSafeMarkdownRenderer } from "@/components/general/GaMarkdownContent";
import { createReferencesRenderer } from "@/components/ask-getabstract/uiFragments/Answer";
import { GaButton } from "@/components/general/GaButton";
import { Language } from "@utils/type/type";
import type { SimpleComponentUiState } from "@/components/ask-getabstract/utils/backgroundWorker";
import type { DeepReadonly } from "vue";
import type { QuestionAnswer } from "@/components/ask-getabstract/utils/store";
import { GaChip } from "@/components/general/GaChip";
import getCurrentLanguage = Language.getCurrentLanguage;

function QuestionAnswerComponent(props: { questionAnswer: DeepReadonly<QuestionAnswer>; displayFullAnswer: boolean; showMore: () => void; smallVersion: boolean }): GaVueComponent {
  const { t } = useI18n();
  const usedReferencesByDataId = getUsedReferencesByDataId(props.questionAnswer.answer, props.questionAnswer.contextDocs);
  const maxWords = props.smallVersion ? 18 : 40;
  const answer = props.displayFullAnswer || props.questionAnswer.answer.split(" ").length < maxWords ? props.questionAnswer.answer : props.questionAnswer.answer.split(" ").slice(0, maxWords).join(" ") + " ...";
  return (
    <>
      <p class="fullSearch__bold fullSearch__question">{props.questionAnswer.question}</p>
      <div class="mb-3 mb-lg-4">
        <div class="fullSearch__askGetabstract-answer-content">
          <GaMarkdownContent
            input={answer}
            defaultRenderer={useSafeMarkdownRenderer(() => (
              <></>
            ))}
            intermediateRenderers={[createReferencesRenderer(usedReferencesByDataId)]}
          />
        </div>
        {!props.displayFullAnswer ? (
          <div class="mb-lg-3">
            <a type="button" href="#" onClick={props.showMore}>
              {t("search:page.askGetabstract.showMore")}
            </a>
          </div>
        ) : null}
      </div>
      {(props.displayFullAnswer && props.smallVersion) || !props.smallVersion ? (
        <>
          <div class="mb-4">
            <p class="fullSearch__bold">{t("search:page.askGetabstract.basedOnSummaries")}</p>
            <div class="d-flex flex-wrap gap-2">
              {props.questionAnswer.contextDocs.map((summary) => (
                <GaChip image={summary.urls.cover} type="link" href={summary.urls.reference}>
                  {summary.title}
                </GaChip>
              ))}
            </div>
          </div>
          {props.questionAnswer.relatedQuestions.length > 0 ? <p class="fullSearch__bold">{t("search:page.askGetabstract.relatedQuestions")}</p> : null}
          {props.questionAnswer.relatedQuestions.slice(0, 2).map((question) => (
            <div class="fullSearch__askGetabstract-question card">
              <a href={"/" + getCurrentLanguage() + "/ask-getabstract?question=" + encodeURIComponent(question)}>{question}</a>
            </div>
          ))}
          <p class="mt-4">{t("search:page.askGetabstract.otherQuestion")}</p>
          <GaButton href={"/" + getCurrentLanguage() + "/ask-getabstract"}>{t("search:page.askGetabstract.title")}</GaButton>
        </>
      ) : null}
    </>
  );
}

function ErrorState(): GaVueComponent {
  const { t } = useI18n();
  return (
    <>
      <div class="mb-lg-5">
        <h3 class="h4">{t("search:page.askGetabstract.errorTitle")}</h3>
        <p>{t("search:page.askGetabstract.errorDescription")}</p>
      </div>
      <div>
        <p>{t("search:page.askGetabstract.errorTryAgain")}</p>
        <GaButton href={"/" + getCurrentLanguage() + "/ask-getabstract"}>{t("search:page.askGetabstract.title")}</GaButton>
      </div>
    </>
  );
}

function LoadingState(props: { smallerVersion: boolean }): GaVueComponent {
  return (
    <>
      <div class="mb-3">
        <span class="placeholder col-7"></span>
        <span class="placeholder col-10"></span>
        <span class="placeholder col-12"></span>
        <span class="placeholder col-11"></span>
        <span class="placeholder col-12"></span>
        <span class="placeholder col-9"></span>
      </div>
      {!props.smallerVersion ? (
        <>
          <div class="mb-3">
            <span class="placeholder col-12"></span>
            <span class="placeholder col-10"></span>
            <span class="placeholder col-12"></span>
            <span class="placeholder col-9"></span>
          </div>
          <div>
            <span class="placeholder col-8 mb-3"></span>
            <div>
              <button class="btn btn-primary fullSearch__askGetabstract-button">&nbsp;</button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

function StateHandler(props: { state: SimpleComponentUiState; displayFullAnswer: boolean; showMore: () => void; smallVersion: boolean }): GaVueComponent {
  const { t } = useI18n();
  switch (props.state.kind) {
    case "INITIAL":
      return <h3>{t("search:askGa.windowTitle")}</h3>;
    case "LOADING":
      return <LoadingState smallerVersion={props.smallVersion} />;
    case "STREAMING":
      return <LoadingState smallerVersion={props.smallVersion} />;
    case "IDLE":
      if (props.state.value.current.status !== "SUCCESS") {
        return <ErrorState />;
      }
      return <QuestionAnswerComponent questionAnswer={props.state.value.current} displayFullAnswer={props.displayFullAnswer} showMore={props.showMore} smallVersion={props.smallVersion} />;
  }
}

export function SearchAskGetAbstractComponent(props: { state: SimpleComponentUiState; displayFullAnswer: boolean; showMore: () => void; smallVersion: boolean }): GaVueComponent {
  const { t } = useI18n();
  return (
    <div class="fullSearch__askGetabstract">
      <h2 class="h3">{t("search:page.askGetabstract.componentTitle")}</h2>
      <p class="small mb-4">{t("search:page.askGetabstract.componentSubtitle")}</p>
      <StateHandler state={props.state} displayFullAnswer={props.displayFullAnswer} showMore={props.showMore} smallVersion={props.smallVersion} />
    </div>
  );
}
