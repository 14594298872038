import { registerModule } from "@/Registry";

export type ProgressProperties = {
  courseProgress: number;
  courseCompleted: boolean;
  actionableCompleted: boolean;
};

export type ResponseObject = {
  [key: number]: ProgressProperties;
};

function hasMember<K extends string, T>(obj: T | null, key: K): obj is T & Record<K, unknown> {
  if (obj == null) {
    return false;
  }
  if (typeof obj !== "object") {
    return false;
  }
  return key in obj;
}

export function parse(obj: unknown): ResponseObject | null {
  const response: ResponseObject = {};
  if (typeof obj !== "object" || obj == null) {
    return null;
  }
  // eslint-disable-next-line no-restricted-properties
  for (const [key, value] of Object.entries(obj)) {
    const numberKey = parseInt(key);
    if (!isNaN(numberKey)) {
      if (
        hasMember(value, "courseCompleted") &&
        typeof value.courseCompleted === "boolean" &&
        hasMember(value, "courseProgress") &&
        typeof value.courseProgress === "number" &&
        hasMember(value, "actionableCompleted") &&
        typeof value.actionableCompleted === "boolean"
      ) {
        response[numberKey] = {
          courseCompleted: value.courseCompleted,
          courseProgress: value.courseProgress,
          actionableCompleted: value.actionableCompleted,
        };
      }
    }
  }
  return response;
}

export function updateProgress(scope: Element): Promise<void | Response> {
  const apiUrl = new URL("/gaapi/actionables/progresslist", location.toString());
  scope.querySelectorAll("[data-actionable-progress-update-id]").forEach((element) => {
    if (element instanceof HTMLElement && element.dataset.actionableProgressUpdateId != null) {
      apiUrl.searchParams.append("id", element.dataset.actionableProgressUpdateId);
    }
  });
  return fetch(apiUrl, {
    method: "GET",
  })
    .then((response) => response.json())
    .then((response) => {
      const parsedResponse = parse(response);
      if (parsedResponse != null) {
        // eslint-disable-next-line no-restricted-properties
        for (const [courseId, properties] of Object.entries(parsedResponse)) {
          if (properties.courseProgress > 0 || properties.courseCompleted) {
            const selector = "[data-actionable-progress-update-id='" + courseId + "']";
            const element = scope.querySelector(selector);
            if (element != null) {
              if (properties.actionableCompleted) {
                element.classList.add("action-card--completed");
              } else if (properties.courseProgress > 0) {
                element.classList.add("action-card--started");
              }
            }
          }
        }
      }
    });
}

registerModule("[data-actionable-progress-update]", updateProgress);
