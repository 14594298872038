import { createParentComponent } from "@/common/vueUtils";

type Variant = "primary" | "primary-dark" | "success" | "danger" | "warning" | "muted" | "primary-light" | "secondary-light" | "tertiary-light" | "purple-light" | "orange-light" | "neutral-warm-light" | "gradient-light";

type GaBadgeProps = {
  variant?: Variant;
  icon?: string;
  pill?: boolean;
  caption?: boolean;
};

export const GaBadge = createParentComponent<GaBadgeProps>((props) => {
  const variant: Variant = props.variant ?? "primary";

  function getClasses(): string[] {
    const classes = ["badge", `badge-${variant}`];
    if (props.pill === true) {
      classes.push("rounded-pill");
    }
    if (props.caption === true) {
      classes.push("badge-caption");
    }
    if (props.children === undefined && props.icon !== undefined) {
      classes.push("badge-icon-only");
    }
    return classes;
  }

  return (
    <span class={getClasses()}>
      {props.icon !== undefined ? <i class={`badge-icon ${props.icon}`} aria-hidden="true"></i> : null}
      {props.children !== undefined ? <span class="badge-body">{props.children}</span> : null}
    </span>
  );
});
