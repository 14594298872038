/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { type PeerInsightFormStep, parsePeerInsightFormStep, renderPeerInsightFormStep } from './peerInsightFormStep';
// @ts-ignore
import { Language, DateTime } from "@utils/type/type";
// @ts-ignore
import { type LocalDate, LocalDateUtils } from "@utils/type/LocalDate";
// @ts-ignore
import { assertIsDefined, ensureNonNull } from "@utils/assertion";

export type PeerInsightFeedbackForm = {
    peerInsightFormSteps: Array<PeerInsightFormStep>;
    actionableId: number;
    strictPrivacyEnabled: boolean;
    freeActionableActive: boolean;
}

export const parsePeerInsightFeedbackForm = (data: any): PeerInsightFeedbackForm => {
    return {
        peerInsightFormSteps: ensureNonNull<Array<PeerInsightFormStep>>(data.peerInsightFormSteps, "peerInsightFormSteps is required").map((value: any) => parsePeerInsightFormStep(value)),
        actionableId: ensureNonNull<number>(data.actionableId, "actionableId is required"),
        strictPrivacyEnabled: ensureNonNull<boolean>(data.strictPrivacyEnabled, "strictPrivacyEnabled is required"),
        freeActionableActive: ensureNonNull<boolean>(data.freeActionableActive, "freeActionableActive is required"),
    }
}

export const renderPeerInsightFeedbackForm = (data: PeerInsightFeedbackForm): any => {
    return {
        peerInsightFormSteps: ensureNonNull<Array<PeerInsightFormStep>>(data.peerInsightFormSteps, "peerInsightFormSteps is required").map((value: any) => renderPeerInsightFormStep(value)),
        actionableId: data.actionableId,
        strictPrivacyEnabled: data.strictPrivacyEnabled,
        freeActionableActive: data.freeActionableActive,
    }
}


