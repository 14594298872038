import { ensureNonNull } from "@utils/assertion";
import { type MIABAnalyticsFeedbackEventForm } from "@newgenerated/shared/schema";
import { serialize_MIABAnalyticsFeedbackEventForm, deserialize_MIABAnalyticsFeedbackEventForm } from "@newgenerated/shared/mapper";

export { type MIABAnalyticsFeedbackEventForm } from "@newgenerated/shared/schema";
export const parseMIABAnalyticsFeedbackEventForm = (data: unknown): MIABAnalyticsFeedbackEventForm => {
  return ensureNonNull(deserialize_MIABAnalyticsFeedbackEventForm(JSON.stringify(data)));
};
export const renderMIABAnalyticsFeedbackEventForm = (data: MIABAnalyticsFeedbackEventForm): any => {
  return JSON.parse(serialize_MIABAnalyticsFeedbackEventForm(data));
};
