import type { PeerInsightFeedbackDashboardView } from "@generated/model/peerInsightFeedbackDashboardView";
import type { GaVueComponent } from "@/common/vueUtils";
import type { TranslateFunction } from "@/i18n/i18nSetup";

function getAreaOfExpertiseLabel(areaOfExpertise: string, t: TranslateFunction): string {
  if (areaOfExpertise === "UNDEFINED" || areaOfExpertise === "OTHER") {
    return t("actionable:peerinsight.feedback.anonymousContributor");
  }
  return t("actionable:peerinsight.feedback.areaOfExpertise", [t("customer:areaOfExpertise." + areaOfExpertise.toLowerCase() + ".title")]);
}

export function PeerInsightAnswer(props: { answer: PeerInsightFeedbackDashboardView; t: TranslateFunction; toggleLike: (peerInsightFeedbackId: number) => Promise<void>; freeActionableActive: boolean }): GaVueComponent {
  return (
    <div class="card peerinsight-card__answer no-shadow">
      <p class="peerinsight-card__answer-text">&laquo;{props.answer.feedbackText}&raquo;</p>
      <p class="peerinsight-card__answer-areaOfExpertise">{getAreaOfExpertiseLabel(props.answer.areaOfExpertise, props.t)}</p>
      {!props.freeActionableActive ? (
        <span onClick={() => props.toggleLike(props.answer.peerInsightFeedbackId)} class="peerinsight-card__answer-like">
          <i class={["peerinsight-card__answer-like-icon", props.answer.liked ? "ico-thumbs-up-fill" : "ico-thumbs-up"]} />
          &nbsp;{props.answer.likeCount > 0 ? props.answer.likeCount : ""}
        </span>
      ) : null}
    </div>
  );
}
