import { type DeepReadonly, defineComponent, onMounted, type PropType } from "vue";
import "@/components/ask-getabstract/askGetabstract.scss";
import { TrendingQuestions } from "@/components/ask-getabstract/uiFragments/TrendingQuestions";
import { type ComponentInteractions, type ComponentUiState, type Failures, Interactions, resetInteractions, resetUiState, runBackgroundWorker, type State } from "@/components/ask-getabstract/utils/store";
import { InitialForm } from "@/components/ask-getabstract/uiFragments/InitialForm";
import { Loading } from "@/components/ask-getabstract/uiFragments/Loading";
import { ErrorMessages, FailureAlerts } from "@/components/ask-getabstract/uiFragments/ErrorMessages";
import { CurrentQuestionAnswer } from "@/components/ask-getabstract/uiFragments/CurrentQuestionAnswer";
import { History } from "@/components/ask-getabstract/uiFragments/History";
import type { AskGetabstractProperties } from "@newgenerated/shared/schema";
import type { GaVueComponent } from "@/common/vueUtils";
import { createStore, type Store } from "@/common/storeUtils";
import type { Language } from "@utils/type/type";

export function AskGetabstract(props: { interactionsStore: Store<ComponentInteractions>; uiState: DeepReadonly<ComponentUiState>; language: Language; showAiDataProtectionDisclaimer: boolean }): GaVueComponent {
  function submitQuestion(question: string, trending?: boolean): void {
    Interactions.submitQuestion(props.interactionsStore, question, trending);
  }

  function dismissAlert(failureType: keyof Failures): void {
    Interactions.dismissAlert(props.interactionsStore.sub("dismissAlert"), failureType);
  }

  return (
    <div class="d-grid gap-5">
      <div class="d-grid gap-4">
        <FailureAlerts failures={props.uiState.failures} dismissAlert={dismissAlert} />
        <InitialForm inputStore={props.interactionsStore.sub("input")} submitInitialQuestion={submitQuestion} language={props.language} showAiDataProtectionDisclaimer={props.showAiDataProtectionDisclaimer} />
        {props.uiState.kind === "INITIAL" ? <TrendingQuestions chooseTrendingQuestion={(question) => submitQuestion(question, true)} /> : null}
      </div>
      <History interactionsStore={props.interactionsStore} uiState={props.uiState} />
      <CurrentQuestionAnswer interactionsStore={props.interactionsStore} uiState={props.uiState} />
      <Loading interactionsStore={props.interactionsStore} uiState={props.uiState} />
      <ErrorMessages uiState={props.uiState} />
    </div>
  );
}

export const AskGetabstractWidget = defineComponent({
  props: {
    askGetabstractProperties: {
      type: Object as PropType<AskGetabstractProperties>,
      required: true,
    },
  },
  setup(props) {
    const { question, questionUuid, language, isTrendingQuestion, showAiDataProtectionDisclaimer } = props.askGetabstractProperties;
    const store = createStore<State>({
      interactions: resetInteractions(question ?? ""),
      uiState: resetUiState(),
    });

    onMounted(async () => {
      if (questionUuid !== null && questionUuid.length === 36) {
        // QuestionUuid has been passed through
        Interactions.loadByUuid(store.sub("interactions").sub("question"), questionUuid);
      } else if (question !== null && question.trim().length > 0) {
        // Question has been passed through
        Interactions.submitQuestion(store.sub("interactions"), question, isTrendingQuestion);
      }
      await runBackgroundWorker(store, "default");
    });

    return () => <AskGetabstract interactionsStore={store.sub("interactions")} uiState={store.sub("uiState").get()} language={language} showAiDataProtectionDisclaimer={showAiDataProtectionDisclaimer} />;
  },
});
