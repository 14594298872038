import type { GaVueComponent } from "@/common/vueUtils";
import { GaAlert } from "@/components/general/GaAlert";
import { GaBadge } from "@/components/general/GaBadge";

export function BadgeDemo(): GaVueComponent {
  return (
    <>
      <div class="mb-5">
        <GaBadge variant="muted">Vue</GaBadge>
        <h2>Primary variants</h2>
        <div class="row row-cols-2 gy-3">
          <div class="col">
            <h3>Primary (default)</h3>
            <div class="d-flex flex-wrap gap-1">
              <GaBadge>Primary (default)</GaBadge>
              <GaBadge>Library task</GaBadge>
            </div>
          </div>
          <div class="col">
            <h3>Primary dark</h3>
            <div class="d-flex flex-wrap gap-1">
              <GaBadge variant="primary-dark">Primary dark</GaBadge>
              <GaBadge variant="primary-dark">Coming soon</GaBadge>
            </div>
          </div>
          <div class="col">
            <h3>Muted</h3>
            <div class="d-flex flex-wrap gap-1">
              <GaBadge variant="muted">Muted</GaBadge>
              <GaBadge variant="muted">JSP</GaBadge>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <h2>Semantic variants</h2>
        <div class="row row-cols-2 gy-3">
          <div class="col">
            <h3>Success</h3>
            <div class="d-flex flex-wrap gap-1">
              <GaBadge variant="success">Success</GaBadge>
              <GaBadge variant="success">STEP 3 completed.</GaBadge>
            </div>
          </div>
          <div class="col">
            <h3>Danger</h3>
            <div class="d-flex flex-wrap gap-1">
              <GaBadge variant="danger">Danger</GaBadge>
              <GaBadge variant="danger">Shouldn't be here.</GaBadge>
            </div>
          </div>
          <div class="col">
            <h3>Warning</h3>
            <div class="d-flex flex-wrap gap-1">
              <GaBadge variant="warning">Warning</GaBadge>
              <GaBadge variant="warning">Could end up badly.</GaBadge>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <h2>Light variants</h2>
        <div class="row row-cols-2 gy-3">
          <div class="col">
            <h3>Primary light</h3>
            <div class="d-flex flex-wrap gap-1">
              <GaBadge variant="primary-light">Primary light</GaBadge>
              <GaBadge variant="primary-light">Book Summary</GaBadge>
            </div>
            <br />
            <br />
            <GaAlert variant="warning">Color for "Primary light" not final.</GaAlert>
          </div>
          <div class="col">
            <h3>Secondary light</h3>
            <div class="d-flex flex-wrap gap-1">
              <GaBadge variant="secondary-light">Secondary light</GaBadge>
              <GaBadge variant="secondary-light">Video Talk</GaBadge>
            </div>
          </div>
          <div class="col">
            <h3>Tertiary light</h3>
            <div class="d-flex flex-wrap gap-1">
              <GaBadge variant="tertiary-light">Tertiary light</GaBadge>
              <GaBadge variant="tertiary-light">Learning Path</GaBadge>
            </div>
            <br />
            <br />
            <GaAlert variant="warning">Color for "Tertiary light" not final.</GaAlert>
          </div>
          <div class="col">
            <h3>Purple light</h3>
            <div class="d-flex flex-wrap gap-1">
              <GaBadge variant="purple-light">Purple light</GaBadge>
              <GaBadge variant="purple-light">Actionables</GaBadge>
            </div>
          </div>
          <div class="col">
            <h3>Orange light</h3>
            <div class="d-flex flex-wrap gap-1">
              <GaBadge variant="orange-light">Orange light</GaBadge>
              <GaBadge variant="orange-light">Meeting in a Box</GaBadge>
            </div>
          </div>
          <div class="col">
            <h3>Neutral warm light</h3>
            <div class="d-flex flex-wrap gap-1">
              <GaBadge variant="neutral-warm-light">Neutral warm light</GaBadge>
              <GaBadge variant="neutral-warm-light">Book Review</GaBadge>
            </div>
          </div>
          <div class="col">
            <h3>Gradient light</h3>
            <div class="d-flex flex-wrap gap-1">
              <GaBadge variant="gradient-light">Gradient light</GaBadge>
              <GaBadge variant="gradient-light">AI Prompt</GaBadge>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5">
        <h2>Pill variants</h2>
        <div class="d-flex flex-wrap gap-1">
          <GaBadge pill={true}>Primary (default) pill</GaBadge>
          <GaBadge variant="primary-dark" pill={true}>
            Primary dark pill
          </GaBadge>
          <GaBadge variant="muted" pill={true}>
            Muted pill
          </GaBadge>
          <GaBadge variant="success" pill={true}>
            Success pill
          </GaBadge>
          <GaBadge variant="danger" pill={true}>
            Danger pill
          </GaBadge>
          <GaBadge variant="warning" pill={true}>
            Warning pill
          </GaBadge>
          <GaBadge variant="primary-light" pill={true}>
            Primary light pill
          </GaBadge>
          <GaBadge variant="secondary-light" pill={true}>
            Secondary light pill
          </GaBadge>
          <GaBadge variant="tertiary-light" pill={true}>
            Tertiary light pill
          </GaBadge>
          <GaBadge variant="purple-light" pill={true}>
            Purple light pill
          </GaBadge>
          <GaBadge variant="orange-light" pill={true}>
            Orange light pill
          </GaBadge>
          <GaBadge variant="neutral-warm-light" pill={true}>
            Neutral warm light pill
          </GaBadge>
          <GaBadge variant="gradient-light" pill={true}>
            Gradient light pill
          </GaBadge>
        </div>
      </div>

      <div class="mb-5">
        <h2>Icon variants</h2>
        <div class="d-flex flex-wrap gap-1">
          <GaBadge icon="ico-earphones">Primary (default)</GaBadge>
          <GaBadge icon="ico-earphones" />
          <GaBadge variant="primary-dark" icon="ico-arrow-up-left">
            Primary dark
          </GaBadge>
          <GaBadge variant="primary-dark" icon="ico-arrow-up-left" />
          <GaBadge variant="muted" icon="ico-paper-clip">
            Muted
          </GaBadge>
          <GaBadge variant="muted" icon="ico-paper-clip" />
          <GaBadge variant="success" icon="ico-clock">
            Success
          </GaBadge>
          <GaBadge variant="success" icon="ico-clock" />
          <GaBadge variant="danger" icon="ico-briefcase">
            Danger
          </GaBadge>
          <GaBadge variant="danger" icon="ico-briefcase" />
          <GaBadge variant="warning" icon="ico-info-circle">
            Warning
          </GaBadge>
          <GaBadge variant="warning" icon="ico-info-circle" />
          <GaBadge variant="primary-light" icon="ico-link">
            Primary light
          </GaBadge>
          <GaBadge variant="primary-light" icon="ico-link" />
          <GaBadge variant="secondary-light" icon="ico-filter">
            Secondary light
          </GaBadge>
          <GaBadge variant="secondary-light" icon="ico-filter" />
          <GaBadge variant="tertiary-light" icon="ico-support">
            Tertiary light
          </GaBadge>
          <GaBadge variant="tertiary-light" icon="ico-support" />
          <GaBadge variant="purple-light" icon="ico-note">
            Purple light
          </GaBadge>
          <GaBadge variant="purple-light" icon="ico-note" />
          <GaBadge variant="orange-light" icon="ico-cog">
            Orange light
          </GaBadge>
          <GaBadge variant="orange-light" icon="ico-cog" />
          <GaBadge variant="neutral-warm-light" icon="ico-question-circle">
            Neutral warm light
          </GaBadge>
          <GaBadge variant="neutral-warm-light" icon="ico-question-circle" />
          <GaBadge variant="gradient-light" icon="ico-sparks-2">
            Gradient light
          </GaBadge>
          <GaBadge variant="gradient-light" icon="ico-sparks-2" />
        </div>
      </div>

      <div class="mb-5">
        <h2>Caption variants</h2>
        <div class="d-flex flex-wrap gap-1">
          <GaBadge caption={true}>Primary (default) caption</GaBadge>
          <GaBadge variant="primary-dark" caption={true}>
            Primary dark caption
          </GaBadge>
          <GaBadge variant="muted" caption={true}>
            Muted caption
          </GaBadge>
          <GaBadge variant="success" caption={true}>
            Success caption
          </GaBadge>
          <GaBadge variant="danger" caption={true}>
            Danger caption
          </GaBadge>
          <GaBadge variant="warning" caption={true}>
            Warning caption
          </GaBadge>
          <GaBadge variant="primary-light" caption={true}>
            Primary light caption
          </GaBadge>
          <GaBadge variant="secondary-light" caption={true}>
            Secondary light caption
          </GaBadge>
          <GaBadge variant="tertiary-light" caption={true}>
            Tertiary light caption
          </GaBadge>
          <GaBadge variant="purple-light" caption={true}>
            Purple light caption
          </GaBadge>
          <GaBadge variant="orange-light" caption={true}>
            Orange light caption
          </GaBadge>
          <GaBadge variant="neutral-warm-light" caption={true}>
            Neutral warm light caption
          </GaBadge>
          <GaBadge variant="gradient-light" caption={true}>
            Gradient light caption
          </GaBadge>
        </div>
      </div>
    </>
  );
}
