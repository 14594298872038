import type { GaVueComponent } from "@/common/vueUtils";
import type { ActionableMiniView } from "@generated/model/actionableMiniView";

export function ActionableCardMini(props: { actionable: ActionableMiniView; params?: URLSearchParams; target?: "_blank"; border?: boolean }): GaVueComponent {
  const url = new URL(`${window.location.origin}/actionables/${props.actionable.name}/${props.actionable.actionableId}`);
  if (props.params !== undefined) {
    for (const [key, value] of props.params) {
      url.searchParams.set(key, value);
    }
  }
  return (
    <a href={url.href} class={["action-card-mini", props.border === true ? "action-card-mini--" : ""]} target={props.target} title={props.actionable.title}>
      <img src={props.actionable.coverUri} alt="" class="action-card-mini__img" />
      <h5 class="action-card-mini__title">{props.actionable.title}</h5>
    </a>
  );
}
