import type { ComponentInteractions, ComponentUiState, CurrentUiStatus, QuestionAnswer } from "@/components/ask-getabstract/utils/store";
import type { DeepReadonly } from "vue";
import type { ComponentSimpleInteractions, SimpleComponentUiState } from "@/components/ask-getabstract/utils/backgroundWorker";
import type { AskGetabstractAnswer, AskGetabstractAnswerStatus } from "@newgenerated/shared/schema";
import type { Dict } from "@utils/dictUtils";

export type Internal = {
  state: ComponentUiState;
  previousInteractions: DeepReadonly<ComponentInteractions>;
};

export type SimpleInternal = {
  state: SimpleComponentUiState;
  previousInteractions: ComponentSimpleInteractions;
};

export function aggregateBookmarkMap(list: AskGetabstractAnswer[]): Dict<boolean> {
  return list
    .flatMap((entry) => entry.contextDocs)
    .reduce<Dict<boolean>>((acc, curr) => {
      acc[curr.dataId] = curr.bookmarked;
      return acc;
    }, {});
}

export function convertFinalStatus(kind: AskGetabstractAnswerStatus): CurrentUiStatus {
  switch (kind) {
    case "NO_DOCS_FROM_VECTOR_DB":
    case "NO_DOCS_AFTER_FILTER":
    case "NO_REFERENCE_IN_ANSWER":
      return "ERROR_NO_ANSWER";
    case "TIMEOUT":
    case "GENERAL_ERROR":
      return "ERROR_GENERAL";
    case "FINISHED":
      return "SUCCESS";
    case "PROCESSING":
      throw Error("`PROCESSING` is not expected to be final status");
  }
}

export function createEmptyQuestionAnswer(props: { question: string; questionUuid: string }): DeepReadonly<QuestionAnswer> {
  return {
    question: props.question,
    questionUuid: props.questionUuid,
    answer: "",
    contextDocs: [],
    detectedLanguageLabel: null,
    relatedActionables: [],
    relatedQuestions: [],
  };
}
