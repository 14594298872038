import type { DeepReadonly } from "vue";
import { assert } from "@utils/assertion";
import type { AskGetabstractAnswerContextDoc } from "@newgenerated/shared/schema";

type AnswerNode =
  | {
      kind: "text";
      text: string;
    }
  | {
      kind: "reference";
      reference: AskGetabstractAnswerContextDoc;
    };

export type ReferencesByDataId = Map<number, AskGetabstractAnswerContextDoc>;

function createReferencePattern(dataId: number): RegExp {
  return new RegExp(`\\[${dataId}]|【${dataId}】`); // Japanese often uses "Lenticular brackets" `【】` instead of regular square brackets
}

function extractReferenceNodes(text: string, dataId: number, usedReferencesByDataId: ReferencesByDataId): AnswerNode[] {
  const pattern = createReferencePattern(dataId);
  return text.split(pattern).reduce<AnswerNode[]>((acc, curr, index, list) => {
    const isLast = list.length - 1 === index;
    if (isLast) {
      return [...acc, { kind: "text", text: curr }];
    }
    const reference = usedReferencesByDataId.get(dataId);
    assert(reference !== undefined);
    return [...acc, { kind: "text", text: curr }, { kind: "reference", reference }];
  }, []);
}

export function getUsedReferencesByDataId(answer: string, references: DeepReadonly<AskGetabstractAnswerContextDoc[]>): ReferencesByDataId {
  return references
    .map((reference) => {
      const pattern = createReferencePattern(reference.dataId);
      const index = answer.search(pattern);
      return { index, reference };
    })
    .filter((reference) => reference.index >= 0)
    .sort((lhs, rhs) => {
      return lhs.index > rhs.index ? 1 : -1;
    })
    .reduce<ReferencesByDataId>((acc, { reference }, i) => {
      acc.set(reference.dataId, { ...reference, referenceNumber: i + 1 });
      return acc;
    }, new Map());
}

export function parseReferences(answer: string, usedReferencesByDataId: ReferencesByDataId): AnswerNode[] {
  return [...usedReferencesByDataId.values()].reduce<AnswerNode[]>(
    (nodes, reference) => {
      return nodes.flatMap((node) => {
        switch (node.kind) {
          case "text":
            return extractReferenceNodes(node.text, reference.dataId, usedReferencesByDataId);
          case "reference":
            return node;
        }
      });
    },
    [{ kind: "text", text: answer }],
  );
}

export function sortReferencesByIndex(answer: string, references: DeepReadonly<AskGetabstractAnswerContextDoc[]>): DeepReadonly<AskGetabstractAnswerContextDoc[]> {
  return Array.from(getUsedReferencesByDataId(answer, references).values()).sort((lhs, rhs) => (lhs.referenceNumber > rhs.referenceNumber ? 1 : -1));
}
