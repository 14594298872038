/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { type OutboundLinkType, parseOutboundLinkType, renderOutboundLinkType } from './outboundLinkType';
// @ts-ignore
import { Language, DateTime } from "@utils/type/type";
// @ts-ignore
import { type LocalDate, LocalDateUtils } from "@utils/type/LocalDate";
// @ts-ignore
import { assertIsDefined, ensureNonNull } from "@utils/assertion";

export type OutboundLink = {
    id: number | null;
    meta: string;
    type: OutboundLinkType;
    targetName: string | null;
    targetUrl: string;
}

export const parseOutboundLink = (data: any): OutboundLink => {
    return {
        id: data.id == null ? null : data.id,
        meta: ensureNonNull<string>(data.meta, "meta is required"),
        type: parseOutboundLinkType(ensureNonNull<OutboundLinkType>(data.type, "type is required")),
        targetName: data.targetName == null ? null : data.targetName,
        targetUrl: ensureNonNull<string>(data.targetUrl, "targetUrl is required"),
    }
}

export const renderOutboundLink = (data: OutboundLink): any => {
    return {
        id: data.id == null ? null : data.id,
        meta: data.meta,
        type: renderOutboundLinkType(data.type),
        targetName: data.targetName == null ? null : data.targetName,
        targetUrl: data.targetUrl,
    }
}


