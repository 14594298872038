import { gaInitGeneral, registerModule } from "@/Registry";
import { ensureNonNull } from "@utils/assertion";

export async function updatePortletContent(element: Element, fetchPortletContent: (uri: string) => Promise<string | Error>): Promise<boolean> {
  const uri = ensureNonNull(element.getAttribute("data-portlet-loader-uri"));
  const response = await fetchPortletContent(uri);
  if (response instanceof Error) {
    element.innerHTML = "Unable to fetch content";
    return false;
  } else {
    const doc = new DOMParser().parseFromString(response, "text/html");
    element.innerHTML = ensureNonNull(doc.querySelector("#main-content")).innerHTML;
    return true;
  }
}

export async function initGaPortletLoader(element: Element): Promise<void> {
  const success = await updatePortletContent(element, async (uri) => {
    const response = await fetch(uri);
    if (!response.ok) {
      return new Error(response.statusText);
    }
    return response.text();
  });
  if (success) {
    gaInitGeneral(element);
  }
}

/**
 * Special event listener for ga-bookmarker plugin. Reloads the BOOKMARKS portlets (if any present)
 */
document.addEventListener("ga:bookmark:updated", function () {
  document.querySelectorAll("[data-portlet-loader-subtype='BOOKMARKS']").forEach((e) => initGaPortletLoader(e));
});

registerModule("[data-portlet-loader]", (e: Element) => initGaPortletLoader(e));
