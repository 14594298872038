import { registerModule } from "@/Registry";
import { assert, assertIsDefined } from "@utils/assertion";

function updateCards(cards: NodeListOf<Element>, keyword: string): void {
  cards.forEach((card) => {
    assert(card instanceof HTMLElement);
    const keywords = card.dataset.filterableKeywords?.split(",");

    card.hidden = !((keywords !== undefined && keywords.includes(keyword)) || keyword === "");
  });
}

function initFilterableContent(wrapperElement: Element): void {
  const cards = wrapperElement.querySelectorAll("[data-filterable-keywords]");
  const chips = wrapperElement.querySelectorAll("[data-ga-filter]");
  chips.forEach((chip) => {
    assert(chip instanceof HTMLInputElement);
    const keyword = chip.dataset.gaFilter;

    assertIsDefined(keyword);

    if (chip.checked) {
      updateCards(cards, keyword);
    }

    chip.addEventListener("activated.ga.chip", () => {
      updateCards(cards, keyword);
    });
  });
}

registerModule("[data-ga-filterable-content]", initFilterableContent);
