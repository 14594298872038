import { ensureNonNull } from "@utils/assertion";
import { type ShareEvent } from "@newgenerated/shared/schema";
import { serialize_ShareEvent, deserialize_ShareEvent } from "@newgenerated/shared/mapper";

export { type ShareEvent } from "@newgenerated/shared/schema";
export const parseShareEvent = (data: unknown): ShareEvent => {
  return ensureNonNull(deserialize_ShareEvent(JSON.stringify(data)));
};
export const renderShareEvent = (data: ShareEvent): any => {
  return JSON.parse(serialize_ShareEvent(data));
};
