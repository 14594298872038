/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Language, DateTime } from "@utils/type/type";
// @ts-ignore
import { type LocalDate, LocalDateUtils } from "@utils/type/LocalDate";
// @ts-ignore
import { assertIsDefined, ensureNonNull } from "@utils/assertion";

export type SuggestedAnswerView = {
    suggestedAnswerId: number | null;
    actionableId: number;
    peerInsightQuestionId: number;
    title: string;
    description: string;
}

export const parseSuggestedAnswerView = (data: any): SuggestedAnswerView => {
    return {
        suggestedAnswerId: data.suggestedAnswerId == null ? null : data.suggestedAnswerId,
        actionableId: ensureNonNull<number>(data.actionableId, "actionableId is required"),
        peerInsightQuestionId: ensureNonNull<number>(data.peerInsightQuestionId, "peerInsightQuestionId is required"),
        title: ensureNonNull<string>(data.title, "title is required"),
        description: ensureNonNull<string>(data.description, "description is required"),
    }
}

export const renderSuggestedAnswerView = (data: SuggestedAnswerView): any => {
    return {
        suggestedAnswerId: data.suggestedAnswerId == null ? null : data.suggestedAnswerId,
        actionableId: data.actionableId,
        peerInsightQuestionId: data.peerInsightQuestionId,
        title: data.title,
        description: data.description,
    }
}


