import { ensureNonNull } from "@utils/assertion";
import { type AskGetabstractQuestionAnswers } from "@newgenerated/shared/schema";
import { serialize_AskGetabstractQuestionAnswers, deserialize_AskGetabstractQuestionAnswers } from "@newgenerated/shared/mapper";

export { type AskGetabstractQuestionAnswers } from "@newgenerated/shared/schema";
export const parseAskGetabstractQuestionAnswers = (data: unknown): AskGetabstractQuestionAnswers => {
  return ensureNonNull(deserialize_AskGetabstractQuestionAnswers(JSON.stringify(data)));
};
export const renderAskGetabstractQuestionAnswers = (data: AskGetabstractQuestionAnswers): any => {
  return JSON.parse(serialize_AskGetabstractQuestionAnswers(data));
};
