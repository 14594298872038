import { useI18n } from "@/i18n/i18nSetup";
import type { GaVueComponent } from "@/common/vueUtils";

export function SelectedQuestion(props: { question: string; isRelatedQuestion: boolean; detectedLanguageLabel: string | null }): GaVueComponent {
  const { t } = useI18n();
  const label = props.isRelatedQuestion ? t("search:askGa.labelForChosenQuestion") : t("search:askGa.labelForInitialQuestion");

  return (
    <div class="d-grid gap-2" id="selectedQuestion">
      <p class="m-0">{label}</p>
      <h4 class="m-0">{props.question}</h4>
      {props.detectedLanguageLabel !== null && props.detectedLanguageLabel.length > 0 ? (
        <p class="small m-0" style="color: var(--ga-neutral-cool-500);">
          <i class="ico-info-circle"></i> {props.detectedLanguageLabel}
        </p>
      ) : null}
    </div>
  );
}
