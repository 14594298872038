import "./_feedback.scss";
import { defineComponent, type PropType, ref } from "vue";

export const ExperienceFeedback = defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    value: {
      type: null as unknown as PropType<number | null>,
      required: true,
    },
    lowestOptionTag: {
      type: String,
      required: true,
    },
    highestOptionTag: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function as PropType<(score: number) => void>,
      required: true,
    },
  },
  setup: (props) => {
    const hoverSelection = ref<number>(0);

    function changeHoverSelection(newValue: number): void {
      hoverSelection.value = newValue;
    }

    function getIconClass(count: number): string[] {
      const classes = ["experience-feedback__icon"];
      const fillUntilValue = props.value === null || hoverSelection.value > props.value ? hoverSelection.value : props.value;
      count <= fillUntilValue ? classes.push("ico-star-fill") : classes.push("ico-star");
      return classes;
    }

    return () => (
      <div class="experience-feedback__container">
        <h3 class="mb-3">{props.title}</h3>
        <h4 class="mb-4 experience-feedback__subtitle">{props.subtitle}</h4>
        <div class="experience-feedback__options" onMouseleave={() => changeHoverSelection(0)}>
          {Array.from({ length: 5 }, (_, i) => i + 1).map((count) => (
            <i class={getIconClass(count).join(" ")} onClick={() => props.onChange(count)} onMouseenter={() => changeHoverSelection(count)} />
          ))}
          <span class="experience-feedback__option-label experience-feedback__option-label--left">
            {props.lowestOptionTag.split("<br>").map((line) => (
              <p>{line}</p>
            ))}
          </span>
          <span class="experience-feedback__option-label experience-feedback__option-label--right">
            {props.highestOptionTag.split("<br>").map((line) => (
              <p>{line}</p>
            ))}
          </span>
        </div>
      </div>
    );
  },
});
