/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { type NavigationDirection, parseNavigationDirection, renderNavigationDirection } from './navigationDirection';
// @ts-ignore
import { type PeerInsightFormStep, parsePeerInsightFormStep, renderPeerInsightFormStep } from './peerInsightFormStep';
// @ts-ignore
import { Language, DateTime } from "@utils/type/type";
// @ts-ignore
import { type LocalDate, LocalDateUtils } from "@utils/type/LocalDate";
// @ts-ignore
import { assertIsDefined, ensureNonNull } from "@utils/assertion";

export type PeerInsightFormNavigation = {
    peerInsightFormSteps: Array<PeerInsightFormStep>;
    navigationDirection: NavigationDirection;
    peerInsightQuestionId: number;
}

export const parsePeerInsightFormNavigation = (data: any): PeerInsightFormNavigation => {
    return {
        peerInsightFormSteps: ensureNonNull<Array<PeerInsightFormStep>>(data.peerInsightFormSteps, "peerInsightFormSteps is required").map((value: any) => parsePeerInsightFormStep(value)),
        navigationDirection: parseNavigationDirection(ensureNonNull<NavigationDirection>(data.navigationDirection, "navigationDirection is required")),
        peerInsightQuestionId: ensureNonNull<number>(data.peerInsightQuestionId, "peerInsightQuestionId is required"),
    }
}

export const renderPeerInsightFormNavigation = (data: PeerInsightFormNavigation): any => {
    return {
        peerInsightFormSteps: ensureNonNull<Array<PeerInsightFormStep>>(data.peerInsightFormSteps, "peerInsightFormSteps is required").map((value: any) => renderPeerInsightFormStep(value)),
        navigationDirection: renderNavigationDirection(data.navigationDirection),
        peerInsightQuestionId: data.peerInsightQuestionId,
    }
}


