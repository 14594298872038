import { type DeepReadonly, nextTick, ref } from "vue";
import { useI18n } from "@/i18n/i18nSetup";
import type { FormFilter, FormFilterName, FullSearchProps, SearchFiltersForm } from "@newgenerated/shared/schema";
import { assert, assertIsDefined } from "@utils/assertion";
import { simpleBackgroundWorker } from "@/components/ask-getabstract/utils/backgroundWorker";
import { delay } from "@utils/asyncUtils";
import { startTimer } from "@/components/ask-getabstract/utils/animationUtils";
import { STREAMING_SPEED_UP_FACTOR, STREAMING_TOKEN_PER_SEC, WAITING_INTERVAL_IN_MS } from "@/components/ask-getabstract/utils/store";
import { type ContentTypeDiscriminator, type ContentTypeProps, defaultAmount, type FormFilterExtended, type FullSearchStoreApiMethods, type SearchStoreActions, type SearchStoreState } from "@/components/search/fullSearchStoreTypes";
import type { MultiSelectStateProps } from "@/components/form/GaFormFieldMultiSelect";
import { Language } from "@utils/type/type";

function getFilter(formFilters: FormFilterExtended[], filterType: FormFilterName): FormFilterExtended | null {
  return formFilters.find((filter) => filter.identifier === filterType) ?? null;
}

export function getActiveFilterValues(formFilters: FormFilterExtended[], filterType: FormFilterName): string[] {
  const filter = getFilter(formFilters, filterType);
  return filter !== null ? filter.activeValues : [];
}

function convertToLanguages(values: string[]): Language[] {
  return values.map((value) => {
    assert(Language.getValues().includes(value as Language));
    return value as Language;
  });
}

function convertToNumbers(input: string[]): number[] {
  return input.map((value) => {
    const number = parseInt(value);
    if (Number.isNaN(number)) {
      throw Error("could not parse number");
    }
    return number;
  });
}

function getContentType(contentTypes: ContentTypeProps[], discriminator: ContentTypeDiscriminator): ContentTypeProps {
  const contentType = contentTypes.filterEquals("discriminator", discriminator).at(0);
  assertIsDefined(contentType);
  return contentType;
}

function convertToSearchFiltersForm(summaryFormFilters: FormFilterExtended[], actionableFormFilters: FormFilterExtended[], searchTerm: string, summariesPage: number, channelpage: number): SearchFiltersForm {
  const audioFilter = getActiveFilterValues(summaryFormFilters, "AUDIO").at(0);
  assert(audioFilter === "true" || audioFilter === "false" || audioFilter === undefined);
  return {
    qualityFormFilter: getActiveFilterValues(summaryFormFilters, "QUALITY"),
    ratingFormFilter: convertToNumbers(getActiveFilterValues(summaryFormFilters, "RATING")),
    sourceFormFilter: getActiveFilterValues(summaryFormFilters, "SOURCE"),
    languageFormFilter: getActiveFilterValues(summaryFormFilters, "LANGUAGE"),
    audioFormFilter: audioFilter === "true",
    publicationDateFormFilter: convertToNumbers(getActiveFilterValues(summaryFormFilters, "PUBLICATION_DATE")),
    actionableLanguageFormFilter: convertToLanguages(getActiveFilterValues(actionableFormFilters, "LANGUAGE")),
    query: searchTerm,
    summariesPage: summariesPage,
    channelPage: channelpage,
  };
}

export function applyQueryParamsToUrl(contentTypeProps: ContentTypeProps[]): void {
  const queryParams = new URLSearchParams(window.location.search);
  contentTypeProps.forEach((contentType) =>
    contentType.formFilters.forEach((filter) => {
      const parameterName = (contentType.discriminator !== "SUMMARY" ? contentType.discriminator.toLowerCase() : "") + filter.name;
      queryParams.delete(parameterName);
      filter.activeValues.forEach((value) => {
        if (!(filter.identifier === "AUDIO" && filter.activeValues.at(0) === "false")) {
          queryParams.append(parameterName, value);
        }
      });
    }),
  );
  history.replaceState(null, "", "?" + queryParams.toString());
}

function readQueryParametersFromUrl(formFilters: FormFilterExtended[], contentType: ContentTypeDiscriminator): FormFilterExtended[] {
  const queryParams = new URLSearchParams(window.location.search);
  return formFilters.map((filter) => {
    const filterName = contentType.toLowerCase() + filter.name;
    const values = queryParams.getAll(filterName);
    return {
      ...filter,
      activeValues: values.length > 0 ? values : filter.activeValues,
    };
  });
}

function getMultiSelectProps(formFilter: FormFilter): MultiSelectStateProps<string> | null {
  if (formFilter.type === "MULTISELECT") {
    return {
      searchTerm: "",
      options: formFilter.options,
      showSearch: false,
      selectedOptions: formFilter.activeValues.map((value) => formFilter.options.filter((option) => option.value === value)).flat(),
    };
  }
  return null;
}

export function createStore(
  props: FullSearchProps,
  apiMethods: FullSearchStoreApiMethods,
): {
  state: () => DeepReadonly<SearchStoreState>;
  actions: SearchStoreActions;
} {
  const { t } = useI18n();
  const convertedSummaryFormFilters = props.initialSummaryFormFilters.map((f) => ({ ...f, isCollapsed: true, multiSelectProps: getMultiSelectProps(f) }));
  const qualityFormFilter = getFilter(convertedSummaryFormFilters, "QUALITY");
  const state = ref<SearchStoreState>({
    contentTypeProps: [
      { discriminator: "SUMMARY", title: t("general:summaries"), amountToShow: defaultAmount["SUMMARY"], items: [], formFilters: readQueryParametersFromUrl(convertedSummaryFormFilters, "SUMMARY") },
      {
        discriminator: "ACTIONABLE",
        title: t("general:actionables"),
        amountToShow: defaultAmount["ACTIONABLE"],
        items: [],
        formFilters: readQueryParametersFromUrl(
          props.initialActionableFormFilters.map((f) => ({ ...f, isCollapsed: true, multiSelectProps: getMultiSelectProps(f) })),
          "ACTIONABLE",
        ),
      },
      { discriminator: "CUSTOMPAGE", title: t("customPage:customPages"), amountToShow: defaultAmount["CUSTOMPAGE"], items: [], formFilters: [] },
      { discriminator: "CHANNEL", title: props.beta ? t("general:skills") : t("general:channels"), amountToShow: defaultAmount["CHANNEL"], items: [], formFilters: [] },
    ],
    searchTerm: "",
    selectedContentType: "ALL",
    status: "LOADING",
    filtersExtended: false,
    multiSelectProps: {
      options: qualityFormFilter !== null ? qualityFormFilter.options : [],
      searchTerm: "",
      selectedOptions: [],
      showSearch: false,
    },
    summariesPaging: {
      page: 0,
      totalCount: BigInt(0),
    },
    channelPaging: {
      page: 0,
      totalCount: BigInt(0),
    },
    params: new URLSearchParams(),
    aiState: {
      feature: "NOT_AVAILABLE",
    },
  });

  apiMethods.hasFeature("GET_ABSTRACT_AI").then((featureAvailable) => {
    if (featureAvailable) {
      state.value.aiState = {
        feature: "AVAILABLE",
        displayFullAnswer: false,
        uiState: { kind: "INITIAL" },
      };

      void simpleBackgroundWorker(
        {
          streamingTokensPerSec: STREAMING_TOKEN_PER_SEC,
          streamingSpeedUpFactor: STREAMING_SPEED_UP_FACTOR,
          analyticsEventVariant: "qa_triggered_from_search",
        },
        {
          getInteractions: () => state.value,
          updateUi: (newState) => {
            assert(state.value.aiState.feature === "AVAILABLE");
            state.value = { ...state.value, aiState: { ...state.value.aiState, uiState: newState } };
          },
          initQuestionAnswer: apiMethods.createQuestion,
          getQuestionAnswers: apiMethods.getQuestionAnswers,
          delay: () => delay(WAITING_INTERVAL_IN_MS),
          isAborted: () => false,
          startTimer,
        },
      );
    }
  });

  const actions: SearchStoreActions = {
    onMultiSelectSearchTermChange(contentType, formFilterName, searchTerm): void {
      const contentTypeProps = { ...getContentType(state.value.contentTypeProps, contentType) };
      const filter = getFilter(contentTypeProps.formFilters, formFilterName);
      assertIsDefined(filter);
      assertIsDefined(filter.multiSelectProps);
      filter.multiSelectProps.searchTerm = searchTerm;
      state.value = { ...state.value, contentTypeProps: [...state.value.contentTypeProps.filter((c) => c.discriminator !== contentType), contentTypeProps] };
    },
    toggleMultiSelect(contentType, formFilterName): void {
      const contentTypeProps = { ...getContentType(state.value.contentTypeProps, contentType) };
      const filter = getFilter(contentTypeProps.formFilters, formFilterName);
      assertIsDefined(filter);
      assertIsDefined(filter.multiSelectProps);
      filter.multiSelectProps.showSearch = !filter.multiSelectProps.showSearch;
      state.value = { ...state.value, contentTypeProps: [...state.value.contentTypeProps.filter((c) => c.discriminator !== contentType), contentTypeProps] };
    },
    search: async (softReload): Promise<void> => {
      state.value = { ...state.value, status: softReload === true ? "LOADMORE" : "LOADING", searchTerm: props.query ?? "" };
      try {
        const result = await apiMethods.search(
          convertToSearchFiltersForm(
            getContentType(state.value.contentTypeProps, "SUMMARY").formFilters,
            getContentType(state.value.contentTypeProps, "ACTIONABLE").formFilters,
            state.value.searchTerm,
            state.value.summariesPaging.page,
            state.value.channelPaging.page,
          ),
        );
        const contentTypes: ContentTypeProps[] = state.value.contentTypeProps.map((cat) => {
          switch (cat.discriminator) {
            case "CHANNEL":
              return { ...cat, items: result.channels };
            case "SUMMARY":
              return { ...cat, items: result.summaries, formFilters: result.summaryFormFilters.map((f) => ({ ...f, isCollapsed: true, multiSelectProps: getMultiSelectProps(f) })) };
            case "CUSTOMPAGE":
              return { ...cat, items: result.customPages };
            case "ACTIONABLE":
              return { ...cat, items: result.actionables, formFilters: result.actionableFormFilters.map((f) => ({ ...f, isCollapsed: true, multiSelectProps: getMultiSelectProps(f) })) };
          }
        });
        applyQueryParamsToUrl(contentTypes);
        state.value = {
          ...state.value,
          contentTypeProps: contentTypes,
          summariesPaging: result.summariesPaging,
          channelPaging: result.channelPaging,
          params: new URLSearchParams(result.downloadSourceParams.additionalProperties),
          status: "IDLE",
        };
      } catch (_) {
        state.value = { ...state.value, status: "ERROR" };
      }
    },
    selectContentType: (contentType) => {
      state.value = { ...state.value, selectedContentType: contentType };
      void nextTick(() => {
        scrollTo({ top: 0 });
      });
    },
    updateFilter: (filterName, newActiveValues, contentType): ContentTypeProps[] => {
      const contentTypeProps = { ...getContentType(state.value.contentTypeProps, contentType) };
      const filter = getFilter(contentTypeProps.formFilters, filterName);
      assertIsDefined(filter);
      filter.activeValues = newActiveValues;
      if (filter.multiSelectProps !== null) {
        filter.multiSelectProps.selectedOptions = filter.multiSelectProps.options.filter((o) => newActiveValues.includes(o.value));
      }
      state.value = { ...state.value, contentTypeProps: [...state.value.contentTypeProps.filter((c) => c.discriminator !== contentType), contentTypeProps] };
      return state.value.contentTypeProps;
    },
    toggleFilters: () => {
      state.value = { ...state.value, filtersExtended: !state.value.filtersExtended };
    },
    resetAllFilters: (contentType?: ContentTypeDiscriminator) => {
      state.value.contentTypeProps
        .filter((c) => contentType === undefined || c.discriminator === contentType)
        .forEach((c) => {
          c.formFilters.forEach((f) => {
            if (f.activeValues.length > 0) {
              actions.updateFilter(f.identifier, [], c.discriminator);
            }
          });
        });
      void actions.search();
    },
    loadMoreSummaries: async () => {
      state.value = { ...state.value, summariesPaging: { ...state.value.summariesPaging, page: state.value.summariesPaging.page + 1 } };
      await actions.search(true);
    },
    loadMoreChannels: async () => {
      state.value = { ...state.value, channelPaging: { ...state.value.channelPaging, page: state.value.channelPaging.page + 1 } };
      await actions.search(true);
    },
    toggleSingleFilter: (filterName: FormFilterName, contentType: ContentTypeDiscriminator) => {
      const contentTypeProps = { ...getContentType(state.value.contentTypeProps, contentType) };
      const filter = getFilter(contentTypeProps.formFilters, filterName);
      assertIsDefined(filter);
      filter.isCollapsed = !filter.isCollapsed;
      state.value = { ...state.value, contentTypeProps: [...state.value.contentTypeProps.filter((c) => c.discriminator !== contentType), contentTypeProps] };
    },
    showFullAiAnswer: () => {
      assert(state.value.aiState.feature === "AVAILABLE");
      state.value = { ...state.value, aiState: { ...state.value.aiState, displayFullAnswer: true } };
    },
  };
  if (props.query !== null && props.query !== "") {
    void actions.search();
  }
  return {
    state: () => state.value,
    actions: actions,
  };
}
