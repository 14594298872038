/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { getHttpClient } from "@utils/httpClient";

/* tslint:disable:no-unused-locals */
// @ts-ignore
import { type ActionablePageProperties, parseActionablePageProperties, renderActionablePageProperties } from '../model/actionablePageProperties';
// @ts-ignore
import type { Page } from "@utils/type/type";


/**
* Get the properties for the step 3 form and dashboard.
* @param actionableId 
*/
export async function getActionablePageProperties(actionableId: number): Promise<ActionablePageProperties> {
        return getHttpClient().request<ActionablePageProperties>({
            url: '/gaapi/actionables/{actionableId}/getProperties'
                .replace('{' + 'actionableId' + '}', encodeURIComponent(String(actionableId))),
            method: 'GET'
        })
        .then(response => {
               return parseActionablePageProperties(response.data);
        });
}

