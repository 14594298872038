import type { GaVueComponent } from "@/common/vueUtils";
import type { DeepReadonly } from "vue";
import type { ComponentInteractions, ComponentUiState } from "@/components/ask-getabstract/utils/store";
import { SelectedQuestion } from "@/components/ask-getabstract/uiFragments/SelectedQuestion";
import { AnswerCard, AnswerCardPlaceholder } from "@/components/ask-getabstract/uiFragments/Answer";
import { Feedback } from "@/components/ask-getabstract/uiFragments/Feedback";
import { References } from "@/components/ask-getabstract/uiFragments/References";
import { RelatedActionables } from "@/components/ask-getabstract/uiFragments/RelatedActionables";
import type { Store } from "@/common/storeUtils";

export function Loading(props: { interactionsStore: Store<ComponentInteractions>; uiState: DeepReadonly<ComponentUiState> }): GaVueComponent | null {
  if (props.uiState.kind !== "LOADING" && props.uiState.kind !== "STREAMING") {
    return null;
  }

  if (props.uiState.kind === "STREAMING") {
    const { current, streamTokenCount } = props.uiState.value;
    const answer = current.answer.split(" ").slice(0, streamTokenCount).join(" ");

    return (
      <div class="d-grid gap-5">
        <SelectedQuestion question={current.question} isRelatedQuestion={props.uiState.value.history.length > 0} detectedLanguageLabel={current.detectedLanguageLabel} />
        <AnswerCard answer={answer} contextDocs={current.contextDocs} questionUuid={current.questionUuid} />
        <Feedback feedbackStore={props.interactionsStore.sub("giveFeedback")} question={current.question} questionUuid={current.questionUuid} showMailFeedbackLink={true} />
        <References bookmarksByDataId={props.uiState.bookmarksByDataId} bookmarkInteractionsStore={props.interactionsStore.sub("toggleBookmarks")} contextDocs={current.contextDocs} answer={answer} questionUuid={current.questionUuid} />
        <RelatedActionables relatedActionables={current.relatedActionables} questionUuid={current.questionUuid} showExplanation={true} />
      </div>
    );
  }

  return (
    <div class="d-grid gap-5">
      <SelectedQuestion question={props.uiState.value.current.question} isRelatedQuestion={props.uiState.value.history.length > 0} detectedLanguageLabel={props.uiState.value.current.detectedLanguageLabel} />
      <AnswerCardPlaceholder />
    </div>
  );
}
