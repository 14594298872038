import { defineComponent, type PropType, ref } from "vue";
import { useI18n } from "@/i18n/i18nSetup";
import { GaButton } from "@/components/general/GaButton";
import { claimContentItems, confirmCreatorIdentity, getVerifaiCreatorPageView, optOutCreator } from "@generated/api/verifaiCreatorPageControllerApi";
import type { VerifaiCreatorView } from "@generated/model/verifaiCreatorView";
import { GaFormFieldInputCheckbox } from "@/components/form/GaFormFieldInputCheckbox";
import { GaTooltip } from "@/components/general/GaTooltip";
import type { VerifaiOverviewItem } from "@generated/model/verifaiOverviewItem";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaAlert } from "@/components/general/GaAlert";
import { GaHtmlContent } from "@/components/general/GaHtmlContent";
import type { IntermediateRenderer } from "@/common/rendererUtils";
import type { VerifaiCreatorPageProps } from "@newgenerated/shared/schema";

type ItemType = "CLAIMING" | "CLAIMED";

export const VerifaiCreatorPage = defineComponent({
  props: {
    verifaiCreatorPageProps: {
      type: Object as PropType<VerifaiCreatorPageProps>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const creatorView = ref<VerifaiCreatorView | null>(null);
    const claimingItems = ref<number[]>([]);
    const validatedIdentity = ref<boolean>(false);
    const claimedSuccess = ref<boolean>(false);
    const optOutSuccess = ref<boolean>(false);

    const init = async (): Promise<void> => {
      creatorView.value = await getVerifaiCreatorPageView(props.verifaiCreatorPageProps.hashId);
    };
    void init();

    const confirmIdentity = async (): Promise<void> => {
      await confirmCreatorIdentity(props.verifaiCreatorPageProps.hashId);
      validatedIdentity.value = true;
    };

    const optOut = async (e: MouseEvent): Promise<void> => {
      e.preventDefault();
      await optOutCreator(props.verifaiCreatorPageProps.hashId);
      optOutSuccess.value = true;
    };

    const claimItems = async (): Promise<void> => {
      creatorView.value = await claimContentItems(props.verifaiCreatorPageProps.hashId, claimingItems.value);
      claimedSuccess.value = true;
    };

    const toggleClaiming = (verifaiCreatorId: number, value: boolean): void => {
      if (value) {
        claimingItems.value.push(verifaiCreatorId);
      } else {
        claimingItems.value.splice(
          claimingItems.value.findIndex((i) => i === verifaiCreatorId),
          1,
        );
      }
    };

    const conditionalSuccessMessage = (): GaVueComponent | null =>
      claimedSuccess.value ? (
        <div class="mb-3">
          <GaAlert variant="success">{t("verify:authorPage.c6.claimedSuccess")}</GaAlert>
        </div>
      ) : optOutSuccess.value ? (
        <div class="mb-3">
          <GaAlert variant="success">{t("verify:authorPage.c6.optOutSuccess")}</GaAlert>
        </div>
      ) : null;

    const linkToOptOutRenderer: IntermediateRenderer<Node> = (node) => {
      return node instanceof HTMLAnchorElement ? (
        <a href="" onClick={optOut}>
          {node.textContent}
        </a>
      ) : (
        "next-renderer"
      );
    };

    const renderItems = (items: VerifaiOverviewItem[], type: ItemType): GaVueComponent => (
      <>
        {items.map((i) => (
          <div class={{ "verifai-creator-section__item": true, "verifai-creator-section__item--disabled": type === "CLAIMED" || !validatedIdentity.value }}>
            <GaFormFieldInputCheckbox
              checked={type === "CLAIMED" || claimingItems.value.includes(i.verifaiContentId)}
              onUpdateChecked={type === "CLAIMING" ? (value) => toggleClaiming(i.verifaiContentId, value) : () => {}}
              disabled={type === "CLAIMED" || !validatedIdentity.value}
              name={type + "_" + i.verifaiContentId}
            />
            <div>
              <label for={type + "_" + i.verifaiContentId}>
                <strong>{i.title}</strong>
              </label>
              <br />
              <a href={i.resourceUrl} target="_blank">
                {i.resourceUrl}
              </a>
            </div>
          </div>
        ))}
      </>
    );

    return () => (
      <div>
        <div class="verifai-creator-section text-center">
          <h2>{t("verify:authorPage.b1.confirmName")}</h2>
          <div class="m-3">
            {creatorView.value !== null ? (
              <div class="col-12 col-md-6 col-lg-3 d-inline-block">
                <input type="text" class={"form-control text-center" + (validatedIdentity.value ? " is-valid" : "")} value={creatorView.value.authorName} disabled />
                {validatedIdentity.value ? <div class="valid-feedback">{t("verify:authorPage.b3.validatedIdentity")}</div> : null}
              </div>
            ) : null}
          </div>
          <GaButton onClick={confirmIdentity} disabled={validatedIdentity.value}>
            {t("verify:authorPage.b2.thisIsMe")}
          </GaButton>
        </div>
        <div class="verifai-creator-section">
          {conditionalSuccessMessage()}
          {creatorView.value !== null && creatorView.value.claimableItems.length > 0 ? (
            <>
              <h3>{t("verify:authorPage.c1.confirmPieces")}</h3>
              {validatedIdentity.value ? (
                <>
                  {renderItems(creatorView.value.claimableItems, "CLAIMING")}
                  <GaButton onClick={claimItems} disabled={!validatedIdentity.value}>
                    {t("verify:authorPage.c4.confirm")}
                  </GaButton>
                </>
              ) : (
                <GaTooltip title={t("verify:authorPage.c2.conformIdentityFirst")} placement="bottom">
                  {renderItems(creatorView.value.claimableItems, "CLAIMING")}
                  <GaButton onClick={() => {}} disabled>
                    {t("verify:authorPage.c4.confirm")}
                  </GaButton>
                </GaTooltip>
              )}

              <div>
                <small>{t("verify:authorPage.c5.confirmDisclaimer")}</small>
              </div>
            </>
          ) : null}
          {creatorView.value !== null && creatorView.value.claimedItems.length > 0 ? (
            <>
              <h3 class={creatorView.value.claimedItems.length > 0 ? "mt-5" : null}>{t("verify:authorPage.c2.confirmedPieces")}</h3>
              {renderItems(creatorView.value.claimedItems, "CLAIMED")}
            </>
          ) : null}
        </div>
        <div class="mt-3 mb-5">
          <small>
            <GaHtmlContent input={t("verify:authorPage.c5.optOutDisclaimer", [""])} intermediateRenderers={[linkToOptOutRenderer]} />
          </small>
        </div>
      </div>
    );
  },
});
