/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { type AreaOfExpertise, parseAreaOfExpertise, renderAreaOfExpertise } from './areaOfExpertise';
// @ts-ignore
import { type PeerInsightQuestionView, parsePeerInsightQuestionView, renderPeerInsightQuestionView } from './peerInsightQuestionView';
// @ts-ignore
import { Language, DateTime } from "@utils/type/type";
// @ts-ignore
import { type LocalDate, LocalDateUtils } from "@utils/type/LocalDate";
// @ts-ignore
import { assertIsDefined, ensureNonNull } from "@utils/assertion";

export type PeerInsightFeedbackDashboardView = {
    peerInsightFeedbackId: number;
    question: PeerInsightQuestionView;
    feedbackText: string;
    areaOfExpertise: AreaOfExpertise;
    likeCount: number;
    liked: boolean;
}

export const parsePeerInsightFeedbackDashboardView = (data: any): PeerInsightFeedbackDashboardView => {
    return {
        peerInsightFeedbackId: ensureNonNull<number>(data.peerInsightFeedbackId, "peerInsightFeedbackId is required"),
        question: parsePeerInsightQuestionView(ensureNonNull<PeerInsightQuestionView>(data.question, "question is required")),
        feedbackText: ensureNonNull<string>(data.feedbackText, "feedbackText is required"),
        areaOfExpertise: parseAreaOfExpertise(ensureNonNull<AreaOfExpertise>(data.areaOfExpertise, "areaOfExpertise is required")),
        likeCount: ensureNonNull<number>(data.likeCount, "likeCount is required"),
        liked: ensureNonNull<boolean>(data.liked, "liked is required"),
    }
}

export const renderPeerInsightFeedbackDashboardView = (data: PeerInsightFeedbackDashboardView): any => {
    return {
        peerInsightFeedbackId: data.peerInsightFeedbackId,
        question: renderPeerInsightQuestionView(data.question),
        feedbackText: data.feedbackText,
        areaOfExpertise: renderAreaOfExpertise(data.areaOfExpertise),
        likeCount: data.likeCount,
        liked: data.liked,
    }
}


