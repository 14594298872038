/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { getHttpClient } from "@utils/httpClient";

/* tslint:disable:no-unused-locals */
// @ts-ignore
import { type VerifaiCreatorView, parseVerifaiCreatorView, renderVerifaiCreatorView } from '../model/verifaiCreatorView';
// @ts-ignore
import type { Page } from "@utils/type/type";


/**
* 
* @summary Claim the selected items to be original from identified author
* @param hashId 
* @param requestBody Array of verifaiContentIds.
*/
export async function claimContentItems(hashId: string, requestBody: Array<number>): Promise<VerifaiCreatorView> {
        return getHttpClient().request<VerifaiCreatorView>({
            url: '/gaapi/verifai/creators-page/{hashId}/claim-items'
                .replace('{' + 'hashId' + '}', encodeURIComponent(String(hashId))),
            method: 'POST',
            data: requestBody
        })
        .then(response => {
               return parseVerifaiCreatorView(response.data);
        });
}
/**
* 
* @summary Confirm the identity of the author
* @param hashId 
*/
export async function confirmCreatorIdentity(hashId: string): Promise<void> {
        return getHttpClient().request({
            url: '/gaapi/verifai/creators-page/{hashId}/confirm-identity'
                .replace('{' + 'hashId' + '}', encodeURIComponent(String(hashId))),
            method: 'POST'
        });
}
/**
* 
* @summary Get all VerifaiCreatorPage information
* @param hashId 
*/
export async function getVerifaiCreatorPageView(hashId: string): Promise<VerifaiCreatorView> {
        return getHttpClient().request<VerifaiCreatorView>({
            url: '/gaapi/verifai/creators-page/{hashId}'
                .replace('{' + 'hashId' + '}', encodeURIComponent(String(hashId))),
            method: 'GET'
        })
        .then(response => {
               return parseVerifaiCreatorView(response.data);
        });
}
/**
* 
* @summary Author opt out from the Verify database
* @param hashId 
*/
export async function optOutCreator(hashId: string): Promise<void> {
        return getHttpClient().request({
            url: '/gaapi/verifai/creators-page/{hashId}/opt-out'
                .replace('{' + 'hashId' + '}', encodeURIComponent(String(hashId))),
            method: 'POST'
        });
}

