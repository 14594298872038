import type { GaVueComponent } from "@/common/vueUtils";
import type { TranslateFunction } from "@/i18n/i18nSetup";

export function LoadingAnimation(props: { t: TranslateFunction }): GaVueComponent {
  return (
    <div class="peerinsight-overlay__animation">
      <span class="peerinsight-form__animation-whiteWall"></span>
      {props
        .t("actionable:peerinsight.form.loadingAnimation.certificate")
        .split("\n")
        .map((value) => (
          <div class="peerinsight-overlay__animation-text">
            <i class="ico-checkmark" />
            <p>{value}</p>
          </div>
        ))}
    </div>
  );
}
