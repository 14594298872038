import type { GaVueComponent } from "@/common/vueUtils";
import type { NavigationDirection } from "@generated/model/navigationDirection";
import type { TranslateFunction } from "@/i18n/i18nSetup";
import type { FormStepExtended, OverlayStatus } from "@/components/actionable/actionable-page/actionablePageStore";
import type { DeepReadonly } from "vue";

export function PeerInsightOverlay(props: {
  children: GaVueComponent;
  open: boolean;
  navigateForm: (direction: NavigationDirection) => void;
  close: () => void;
  t: TranslateFunction;
  currentFormStep: DeepReadonly<FormStepExtended | undefined>;
  overlayStatus: OverlayStatus;
}): GaVueComponent | null {
  if (props.open) {
    return (
      <div class="peerinsight-overlay">
        <div class="peerinsight-overlay__header">
          {props.overlayStatus === "FORM" && props.currentFormStep !== undefined && props.currentFormStep.hasPrevious ? (
            <span class="peerinsight-overlay__icon" onClick={() => props.navigateForm("PREVIOUS")}>
              <i class="ico-arrow-left" />
            </span>
          ) : (
            <span class="peerinsight-overlay__icon peerinsight-overlay__icon-left"></span>
          )}
          <div class="peerinsight-overlay__title">
            <span class="caption">{props.t("actionable:step.3")}</span>
            <h2>{props.t("actionable:peerinsight.form.title")}</h2>
          </div>
          <span class="peerinsight-overlay__icon peerinsight-overlay__icon-right" onClick={() => props.close()}>
            <i class="ico-close" />
          </span>
        </div>
        {props.children}
      </div>
    );
  }
  return null;
}
