import type { ComponentInteractions, ComponentUiState, Failures, IdleUiState, InitialUiState, LoadingUiState, QuestionAnswer, StreamingUiState } from "@/components/ask-getabstract/utils/store";
import { resetInteractions, resetUiState } from "@/components/ask-getabstract/utils/store";
import type { AnimationTimer } from "@/components/ask-getabstract/utils/animationUtils";
import { assert } from "@utils/assertion";
import { diffInteractions } from "@/components/ask-getabstract/utils/backgroundWorkerDiff";
import { createProcessQuestion, createRunPolling } from "@/components/ask-getabstract/utils/backgroundWorkerProcessQuestion";
import type { Internal, SimpleInternal } from "@/components/ask-getabstract/utils/backgroundWorkerUtils";
import { aggregateBookmarkMap, convertFinalStatus, createEmptyQuestionAnswer } from "@/components/ask-getabstract/utils/backgroundWorkerUtils";
import { type DeepReadonly, nextTick } from "vue";
import { runAbortableFunctions } from "@utils/asyncUtils";
import type { AskGetabstractAnalyticsEventVariant, AskGetabstractQuestion, AskGetabstractQuestionAnswers } from "@newgenerated/shared/schema";
import { cloneDeep } from "@utils/objectUtils";
import type { Store } from "@/common/storeUtils";
import type { Dict } from "@utils/dictUtils";
import { dictToList } from "@utils/dictUtils";

export async function backgroundWorker(
  store: Store<ComponentUiState>,
  props: {
    streamingTokensPerSec: number;
    streamingSpeedUpFactor: number;
    analyticsEventVariant: AskGetabstractAnalyticsEventVariant;
  },
  actions: {
    processedFeedback: (questionUuid: string) => void;
    processedBookmark: (dataId: string) => void;
    processedAlertDismissal: (failureType: keyof Failures) => void;
    getInteractions: () => DeepReadonly<ComponentInteractions>;
    initQuestionAnswer: (props: AskGetabstractQuestion) => Promise<void>;
    getQuestionAnswers: (uuid: string) => Promise<AskGetabstractQuestionAnswers>;
    giveFeedback: (questionUuid: string, isPositive: boolean) => Promise<void>;
    toggleBookmark: (dataId: string, bookmarked: boolean) => Promise<void>;
    delay: () => Promise<void>;
    startTimer: () => AnimationTimer;
    isAborted: () => boolean;
  },
): Promise<void> {
  const { getInteractions, initQuestionAnswer, getQuestionAnswers, giveFeedback, toggleBookmark, isAborted, startTimer, delay } = actions;

  const internal: Internal = {
    state: resetUiState(),
    previousInteractions: resetInteractions(""),
  };

  /**
   * Always update the ui to the latest state before waiting.
   */
  async function updateUi(newState: DeepReadonly<ComponentUiState>): Promise<void> {
    const currentState = store.get();
    store.set(newState);

    const questionHasChanged = newState.kind !== "INITIAL" && currentState.kind !== "INITIAL" && newState.value.current.questionUuid !== currentState.value.current.questionUuid;

    if (questionHasChanged) {
      // A new question will collapse earlier questions. The following will help focus on the asked question no matter the layout shift.
      void nextTick(() => {
        const selectedQuestion = document.getElementById("selectedQuestion");
        selectedQuestion?.scrollIntoView({ block: "center" });
      });
    }

    internal.state = newState;
    await delay();
  }

  async function updateWhileProcessing({ history, current }: AskGetabstractQuestionAnswers): Promise<void> {
    const updatedBookmarksByDataId = aggregateBookmarkMap([...history, current]);
    if (internal.state.kind === "LOADING") {
      await updateUi({
        ...internal.state,
        bookmarksByDataId: updatedBookmarksByDataId,
        value: {
          history,
          current,
        },
      });
    } else if (internal.state.kind === "STREAMING") {
      await updateUi({
        ...internal.state,
        bookmarksByDataId: updatedBookmarksByDataId,
        value: {
          history,
          current,
          streamTokenCount: internal.state.value.streamTokenCount,
        },
      });
    }
  }

  async function updateTokenCount(tokenCount: number): Promise<void> {
    if (internal.state.kind !== "INITIAL") {
      await updateUi({
        ...internal.state,
        kind: "STREAMING",
        value: {
          ...internal.state.value,
          streamTokenCount: tokenCount,
        },
      });
    }
  }

  await updateUi(internal.state);

  while (true) {
    if (isAborted()) {
      break;
    }

    const nextInteractions = getInteractions();
    const diff = diffInteractions(internal.previousInteractions, nextInteractions);
    internal.previousInteractions = cloneDeep(nextInteractions);

    if (diff.dismissAlerts.bookmark) {
      await updateUi({ ...internal.state, failures: { ...internal.state.failures, bookmark: false } });
    }

    if (diff.dismissAlerts.feedback) {
      await updateUi({ ...internal.state, failures: { ...internal.state.failures, feedback: false } });
    }

    let { feedbackByUuid, bookmarksByDataId } = internal.state;

    if (dictToList(diff.newBookmarks).length > 0) {
      const newBookmarks: Dict<boolean> = {};
      let failures = { ...internal.state.failures };
      for (const [dataId, bookmarked] of dictToList(diff.newBookmarks)) {
        const oldBookmark = internal.state.bookmarksByDataId[dataId];
        if (oldBookmark === bookmarked) {
          // Distinct: same bookmark change already sent
          continue;
        }
        try {
          await toggleBookmark(dataId, bookmarked);
          newBookmarks[dataId] = bookmarked;
        } catch (_: unknown) {
          failures = { ...failures, bookmark: true };
        }
        actions.processedBookmark(dataId);
      }
      bookmarksByDataId = { ...bookmarksByDataId, ...newBookmarks };
      await updateUi({ ...internal.state, bookmarksByDataId, failures });
    }

    if (dictToList(diff.newFeedback).length > 0) {
      const newFeedback: Dict<boolean> = {};
      let failures = { ...internal.state.failures };
      for (const [questionUuid, isPositive] of dictToList(diff.newFeedback)) {
        const oldFeedback = internal.state.feedbackByUuid[questionUuid];
        if (oldFeedback === isPositive) {
          // Distinct: same feedback already sent
          continue;
        }
        try {
          await giveFeedback(questionUuid, isPositive);
          newFeedback[questionUuid] = isPositive;
        } catch (_: unknown) {
          failures = { ...failures, feedback: true };
        }
        actions.processedFeedback(questionUuid);
      }
      feedbackByUuid = { ...feedbackByUuid, ...newFeedback };
      await updateUi({ ...internal.state, feedbackByUuid, failures });
    }

    if (diff.newQuestion?.kind === "BY_QUESTION") {
      const nextHistory: DeepReadonly<QuestionAnswer[]> = [];
      const nextCurrent = createEmptyQuestionAnswer({
        question: diff.newQuestion.input,
        questionUuid: crypto.randomUUID(),
      });
      await updateUi({
        kind: "LOADING",
        feedbackByUuid,
        bookmarksByDataId,
        failures: internal.state.failures,
        value: {
          history: nextHistory,
          current: nextCurrent,
        },
      });

      const processQuestion = createProcessQuestion(
        {
          current: nextCurrent,
          history: nextHistory,
          isTrendingQuestion: diff.newQuestion.trending,
          streamingTokensPerSec: props.streamingTokensPerSec,
          streamingSpeedUpFactor: props.streamingSpeedUpFactor,
          analyticsEventVariant: props.analyticsEventVariant,
        },
        {
          initQuestionAnswer,
          getQuestionAnswers,
          updateWhileProcessing,
          startTimer,
          delay,
          updateTokenCount,
        },
      );

      const processedResult = await runAbortableFunctions(
        [processQuestion],
        () => {
          if (isAborted()) {
            return true;
          }
          const { question } = getInteractions();
          if (question.kind === "BY_QUESTION" && question.input !== nextCurrent.question) {
            return true;
          }
          return question.kind === "BY_UUID" && question.uuid !== nextCurrent.questionUuid;
        },
        delay,
      );

      if (processedResult.kind === "CANCELLED") {
        continue;
      }

      if (processedResult.kind === "ERROR") {
        await updateUi({
          ...internal.state,
          kind: "IDLE",
          value: {
            history: [],
            current: {
              ...nextCurrent,
              status: "ERROR_GENERAL",
            },
          },
        });
        continue;
      }

      const { current, history } = processedResult.value;
      const updatedBookmarksByDataId = aggregateBookmarkMap([...history, current]);

      await updateUi({
        ...internal.state,
        kind: "IDLE",
        bookmarksByDataId: updatedBookmarksByDataId,
        value: {
          history,
          current: {
            ...current,
            status: convertFinalStatus(current.status),
          },
        },
      });
    }

    if (diff.newQuestion?.kind === "BY_UUID") {
      const questionUuid = diff.newQuestion.uuid;

      const nextHistory: DeepReadonly<QuestionAnswer[]> = [];
      const nextCurrent = createEmptyQuestionAnswer({
        question: "",
        questionUuid,
      });

      await updateUi({
        ...internal.state,
        kind: "LOADING",
        value: {
          history: nextHistory,
          current: nextCurrent,
        },
      });

      const runPolling = createRunPolling(
        {
          questionUuid,
        },
        {
          getQuestionAnswers,
          runUntil: (questionAnswers) => questionAnswers.current.status !== "PROCESSING",
          updateWhileProcessing,
        },
      );

      const pollingResult = await runAbortableFunctions(
        [runPolling],
        () => {
          if (isAborted()) {
            return true;
          }
          const { question } = getInteractions();
          return question.kind === "BY_QUESTION";
        },
        delay,
      );

      if (pollingResult.kind === "CANCELLED") {
        continue;
      }
      if (pollingResult.kind === "ERROR") {
        await updateUi({
          ...internal.state,
          kind: "IDLE",
          value: {
            history: [],
            current: {
              ...nextCurrent,
              status: "ERROR_GENERAL",
            },
          },
        });
        continue;
      }

      const { current, history } = pollingResult.value;
      const updatedBookmarksByDataId = aggregateBookmarkMap([...history, current]);

      await updateUi({
        ...internal.state,
        kind: "IDLE",
        bookmarksByDataId: updatedBookmarksByDataId,
        value: {
          history,
          current: {
            ...current,
            status: convertFinalStatus(current.status),
          },
        },
      });
    }

    if (diff.newQuestion?.kind === "RELATED") {
      assert(internal.state.kind === "IDLE");

      const nextHistory: DeepReadonly<QuestionAnswer[]> = [...internal.state.value.history, internal.state.value.current];
      const nextCurrent = createEmptyQuestionAnswer({
        question: diff.newQuestion.input,
        questionUuid: crypto.randomUUID(),
      });

      await updateUi({
        kind: "LOADING",
        feedbackByUuid,
        bookmarksByDataId,
        failures: internal.state.failures,
        value: {
          history: nextHistory,
          current: nextCurrent,
        },
      });

      const processQuestion = createProcessQuestion(
        {
          current: nextCurrent,
          history: nextHistory,
          isTrendingQuestion: false,
          streamingTokensPerSec: props.streamingTokensPerSec,
          streamingSpeedUpFactor: props.streamingSpeedUpFactor,
          analyticsEventVariant: props.analyticsEventVariant,
        },
        {
          initQuestionAnswer,
          getQuestionAnswers,
          updateWhileProcessing,
          startTimer,
          delay,
          updateTokenCount,
        },
      );
      const processedResult = await runAbortableFunctions(
        [processQuestion],
        () => {
          if (isAborted()) {
            return true;
          }
          const { question } = getInteractions();
          return question.kind === "BY_QUESTION";
        },
        delay,
      );

      if (processedResult.kind === "CANCELLED") {
        continue;
      }
      if (processedResult.kind === "ERROR") {
        await updateUi({
          ...internal.state,
          kind: "IDLE",
          value: {
            history: internal.state.value.history,
            current: {
              ...internal.state.value.current,
              status: "ERROR_GENERAL",
            },
          },
        });
        continue;
      }

      const { current, history } = processedResult.value;
      const updatedBookmarksByDataId = aggregateBookmarkMap([...history, current]);

      await updateUi({
        ...internal.state,
        kind: "IDLE",
        bookmarksByDataId: updatedBookmarksByDataId,
        value: {
          history,
          current: {
            ...current,
            status: convertFinalStatus(current.status),
          },
        },
      });
    }

    await updateUi(internal.state);
  }
}

export type ComponentSimpleInteractions = {
  searchTerm: string;
};

export function resetSimpleInteractions(): ComponentSimpleInteractions {
  return { searchTerm: "" };
}

export type SimpleInteractionsDiff = {
  newSearchTerm: null | string;
};

export function diffSimpleInteractions(prev: ComponentSimpleInteractions, next: ComponentSimpleInteractions): SimpleInteractionsDiff {
  if (prev.searchTerm === next.searchTerm) {
    return { newSearchTerm: null };
  }
  return { newSearchTerm: next.searchTerm };
}

export type SimpleComponentUiState = InitialUiState | LoadingUiState | StreamingUiState | IdleUiState;

export async function simpleBackgroundWorker(
  props: {
    streamingTokensPerSec: number;
    streamingSpeedUpFactor: number;
    analyticsEventVariant: AskGetabstractAnalyticsEventVariant;
  },
  actions: {
    updateUi: (newState: DeepReadonly<SimpleComponentUiState>) => void;
    getInteractions: () => DeepReadonly<ComponentSimpleInteractions>;
    initQuestionAnswer: (props: AskGetabstractQuestion) => Promise<void>;
    getQuestionAnswers: (uuid: string) => Promise<AskGetabstractQuestionAnswers>;
    delay: () => Promise<void>;
    startTimer: () => AnimationTimer;
    isAborted: () => boolean;
  },
): Promise<void> {
  const { getInteractions, initQuestionAnswer, getQuestionAnswers, isAborted, startTimer, delay } = actions;

  const internal: SimpleInternal = {
    state: resetUiState(),
    previousInteractions: resetSimpleInteractions(),
  };

  /**
   * Always update the ui to the latest state before waiting.
   */
  async function updateUi(uiState: SimpleComponentUiState): Promise<void> {
    internal.state = uiState;
    actions.updateUi(internal.state);
    await delay();
  }

  async function updateWhileProcessing({ history, current }: AskGetabstractQuestionAnswers): Promise<void> {
    if (internal.state.kind === "LOADING") {
      await updateUi({
        ...internal.state,
        value: {
          history,
          current,
        },
      });
    } else if (internal.state.kind === "STREAMING") {
      await updateUi({
        ...internal.state,
        value: {
          history,
          current,
          streamTokenCount: internal.state.value.streamTokenCount,
        },
      });
    }
  }

  async function updateTokenCount(tokenCount: number): Promise<void> {
    if (internal.state.kind !== "INITIAL") {
      await updateUi({
        ...internal.state,
        kind: "STREAMING",
        value: {
          ...internal.state.value,
          streamTokenCount: tokenCount,
        },
      });
    }
  }

  await updateUi(internal.state);

  while (true) {
    if (isAborted()) {
      break;
    }

    const nextInteractions = getInteractions();
    const diff = diffSimpleInteractions(internal.previousInteractions, nextInteractions);
    internal.previousInteractions = cloneDeep(nextInteractions);

    if (diff.newSearchTerm !== null) {
      const nextHistory: DeepReadonly<QuestionAnswer[]> = [];
      const nextCurrent = createEmptyQuestionAnswer({
        question: diff.newSearchTerm,
        questionUuid: crypto.randomUUID(),
      });
      await updateUi({
        kind: "LOADING",
        value: {
          history: nextHistory,
          current: nextCurrent,
        },
      });

      const processQuestion = createProcessQuestion(
        {
          current: nextCurrent,
          history: nextHistory,
          isTrendingQuestion: false,
          streamingTokensPerSec: props.streamingTokensPerSec,
          streamingSpeedUpFactor: props.streamingSpeedUpFactor,
          analyticsEventVariant: props.analyticsEventVariant,
        },
        {
          initQuestionAnswer,
          getQuestionAnswers,
          updateWhileProcessing,
          startTimer,
          delay,
          updateTokenCount,
        },
      );

      const processedResult = await runAbortableFunctions(
        [processQuestion],
        () => {
          if (isAborted()) {
            return true;
          }
          const { searchTerm } = getInteractions();
          return searchTerm !== nextCurrent.question;
        },
        delay,
      );

      if (processedResult.kind === "CANCELLED") {
        continue;
      }

      if (processedResult.kind === "ERROR") {
        await updateUi({
          ...internal.state,
          kind: "IDLE",
          value: {
            history: [],
            current: {
              ...nextCurrent,
              status: "ERROR_GENERAL",
            },
          },
        });
        continue;
      }

      const { current, history } = processedResult.value;

      await updateUi({
        ...internal.state,
        kind: "IDLE",
        value: {
          history,
          current: {
            ...current,
            status: convertFinalStatus(current.status),
          },
        },
      });
    }

    await updateUi(internal.state);
  }
}
