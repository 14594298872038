/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { type PeerInsightQuestionView, parsePeerInsightQuestionView, renderPeerInsightQuestionView } from './peerInsightQuestionView';
// @ts-ignore
import { type SuggestedAnswerView, parseSuggestedAnswerView, renderSuggestedAnswerView } from './suggestedAnswerView';
// @ts-ignore
import { Language, DateTime } from "@utils/type/type";
// @ts-ignore
import { type LocalDate, LocalDateUtils } from "@utils/type/LocalDate";
// @ts-ignore
import { assertIsDefined, ensureNonNull } from "@utils/assertion";

export type PeerInsightFormStep = {
    question: PeerInsightQuestionView;
    suggestedAnswers: Array<SuggestedAnswerView>;
    hasNext: boolean;
    hasPrevious: boolean;
    selectedScore: number | null;
    text: string;
    selectedSuggestedAnswers: Array<number>;
}

export const parsePeerInsightFormStep = (data: any): PeerInsightFormStep => {
    return {
        question: parsePeerInsightQuestionView(ensureNonNull<PeerInsightQuestionView>(data.question, "question is required")),
        suggestedAnswers: ensureNonNull<Array<SuggestedAnswerView>>(data.suggestedAnswers, "suggestedAnswers is required").map((value: any) => parseSuggestedAnswerView(value)),
        hasNext: ensureNonNull<boolean>(data.hasNext, "hasNext is required"),
        hasPrevious: ensureNonNull<boolean>(data.hasPrevious, "hasPrevious is required"),
        selectedScore: data.selectedScore == null ? null : data.selectedScore,
        text: ensureNonNull<string>(data.text, "text is required"),
        selectedSuggestedAnswers: ensureNonNull<Array<number>>(data.selectedSuggestedAnswers, "selectedSuggestedAnswers is required").map((value: any) => value),
    }
}

export const renderPeerInsightFormStep = (data: PeerInsightFormStep): any => {
    return {
        question: renderPeerInsightQuestionView(data.question),
        suggestedAnswers: ensureNonNull<Array<SuggestedAnswerView>>(data.suggestedAnswers, "suggestedAnswers is required").map((value: any) => renderSuggestedAnswerView(value)),
        hasNext: data.hasNext,
        hasPrevious: data.hasPrevious,
        selectedScore: data.selectedScore == null ? null : data.selectedScore,
        text: data.text,
        selectedSuggestedAnswers: ensureNonNull<Array<number>>(data.selectedSuggestedAnswers, "selectedSuggestedAnswers is required").map((value: any) => value),
    }
}


