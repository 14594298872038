import { registerModule } from "@/Registry";

export function count(element: Element): void {
  const input = element.querySelector<HTMLTextAreaElement | HTMLInputElement>("[data-ga-form-field-character-counter-input]");
  const output = element.querySelector<HTMLElement>("[data-ga-form-field-character-counter-output]");

  if (input === null || output === null) {
    return;
  }

  input.addEventListener("input", () => {
    output.innerText = String(input.value.length);
  });
}

registerModule("[data-ga-form-field-character-counter='true']", count);
