/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { Language, DateTime } from "@utils/type/type";
// @ts-ignore
import { type LocalDate, LocalDateUtils } from "@utils/type/LocalDate";
// @ts-ignore
import { assertIsDefined, ensureNonNull } from "@utils/assertion";

export type PeerInsightQuestionView = {
    peerInsightQuestionId: number;
    key: string;
    numeric: boolean;
    showSuggestedAnswers: boolean;
    allowedInNonStrictPrivacy: boolean;
    allowedInStrictPrivacy: boolean;
}

export const parsePeerInsightQuestionView = (data: any): PeerInsightQuestionView => {
    return {
        peerInsightQuestionId: ensureNonNull<number>(data.peerInsightQuestionId, "peerInsightQuestionId is required"),
        key: ensureNonNull<string>(data.key, "key is required"),
        numeric: ensureNonNull<boolean>(data.numeric, "numeric is required"),
        showSuggestedAnswers: ensureNonNull<boolean>(data.showSuggestedAnswers, "showSuggestedAnswers is required"),
        allowedInNonStrictPrivacy: ensureNonNull<boolean>(data.allowedInNonStrictPrivacy, "allowedInNonStrictPrivacy is required"),
        allowedInStrictPrivacy: ensureNonNull<boolean>(data.allowedInStrictPrivacy, "allowedInStrictPrivacy is required"),
    }
}

export const renderPeerInsightQuestionView = (data: PeerInsightQuestionView): any => {
    return {
        peerInsightQuestionId: data.peerInsightQuestionId,
        key: data.key,
        numeric: data.numeric,
        showSuggestedAnswers: data.showSuggestedAnswers,
        allowedInNonStrictPrivacy: data.allowedInNonStrictPrivacy,
        allowedInStrictPrivacy: data.allowedInStrictPrivacy,
    }
}


