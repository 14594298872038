import { ensureNonNull } from "@utils/assertion";
import { type AskGetabstractQuestion } from "@newgenerated/shared/schema";
import { serialize_AskGetabstractQuestion, deserialize_AskGetabstractQuestion } from "@newgenerated/shared/mapper";

export { type AskGetabstractQuestion } from "@newgenerated/shared/schema";
export const parseAskGetabstractQuestion = (data: unknown): AskGetabstractQuestion => {
  return ensureNonNull(deserialize_AskGetabstractQuestion(JSON.stringify(data)));
};
export const renderAskGetabstractQuestion = (data: AskGetabstractQuestion): any => {
  return JSON.parse(serialize_AskGetabstractQuestion(data));
};
