import { registerModule } from "@/Registry";
import { assert } from "@utils/assertion";

const registerClickEvent = (element: Element): void => {
  element.addEventListener("click", (event) => {
    event.preventDefault();
    const textContainer = element.closest("[data-ga-promobox-text-box]");
    assert(textContainer !== null);
    textContainer.classList.toggle("promobox-card__text-box--expanded");
  });
};

registerModule("[data-ga-promobox-expand]", registerClickEvent);
