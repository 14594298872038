import "@/components/general/gaProgressCircle.scss";
import { computed, defineComponent, ref, watch } from "vue";

function getBackgroundCssValue(progress: number, progressColor: string, blankColor: string): string {
  return `conic-gradient(${progressColor} ${progress * 3.6}deg, ${blankColor} 0deg)`;
}

export const GaProgressCircle = defineComponent({
  props: {
    progress: {
      type: Number,
      required: true,
    },
    progressCircleColor: {
      type: String,
      required: false,
    },
    blankCircleColor: {
      type: String,
      required: false,
    },
    noProgressIcon: {
      type: String,
      required: false,
    },
  },
  setup: (props) => {
    const progress = ref(props.progress);
    const interval = ref<ReturnType<typeof setInterval> | undefined>(undefined);
    watch(
      () => props.progress,
      (newValue) => {
        clearInterval(interval.value);
        interval.value = setInterval(() => {
          if (progress.value === newValue) {
            clearInterval(interval.value);
          } else if (progress.value > newValue) {
            progress.value = progress.value - 1;
          } else {
            progress.value = progress.value + 1;
          }
        }, 15);
      },
    );

    const blankCircleColor = (): string => {
      return props.blankCircleColor ?? "var(--ga-neutral-cool-300)";
    };

    const backgroundVariable = computed((): string => {
      const circleColor = props.progressCircleColor ?? "var(--ga-blue)";
      return `--circle-progress-background: ${getBackgroundCssValue(progress.value, circleColor, blankCircleColor())};`;
    });

    const colorVariable = computed((): string => {
      return `--blank-circle-color: ${blankCircleColor()};`;
    });

    return () => (
      <div class="progress-circle__container">
        <div class="progress-circle__circular-progress" style={backgroundVariable.value}>
          <span class={[props.noProgressIcon !== undefined && progress.value === 0 ? "progress-circle__progress-value--icon" : "", "progress-circle__progress-value"]} style={colorVariable.value}>
            {props.noProgressIcon !== undefined && progress.value === 0 ? <i class={props.noProgressIcon} /> : progress.value.toString() + "%"}
          </span>
        </div>
      </div>
    );
  },
});
