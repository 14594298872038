/*
 * Handling interactions
 */

import type { ComponentInteractions, Failures } from "@/components/ask-getabstract/utils/store";
import type { DeepReadonly } from "vue";
import { type Dict, dictToList } from "@utils/dictUtils";

export type InteractionsDiff = {
  newQuestion: null | { kind: "BY_QUESTION"; input: string; trending: boolean } | { kind: "BY_UUID"; uuid: string } | { kind: "RELATED"; input: string };
  newFeedback: Dict<boolean>;
  newBookmarks: Dict<boolean>;
  dismissAlerts: Failures;
};

export function diffInteractions(prev: DeepReadonly<ComponentInteractions>, next: DeepReadonly<ComponentInteractions>): InteractionsDiff {
  const diff: InteractionsDiff = { newQuestion: null, newFeedback: {}, newBookmarks: {}, dismissAlerts: { bookmark: false, feedback: false } };
  if (prev.question.kind === "BY_QUESTION" && next.question.kind === "BY_QUESTION") {
    if (prev.question.input !== next.question.input) {
      diff.newQuestion = { kind: "BY_QUESTION", input: next.question.input, trending: next.question.trending ?? false };
    }
  } else if (prev.question.kind === "BY_UUID" && next.question.kind === "BY_UUID") {
    if (prev.question.uuid !== next.question.uuid) {
      diff.newQuestion = { kind: "BY_UUID", uuid: next.question.uuid };
    }
  } else if (prev.question.kind === "RELATED" && next.question.kind === "RELATED") {
    if (prev.question.input !== next.question.input) {
      diff.newQuestion = { kind: "RELATED", input: next.question.input };
    }
  } else if (next.question.kind === "BY_QUESTION") {
    diff.newQuestion = { kind: "BY_QUESTION", input: next.question.input, trending: next.question.trending ?? false };
  } else if (next.question.kind === "RELATED") {
    diff.newQuestion = { kind: "RELATED", input: next.question.input };
  } else {
    diff.newQuestion = { kind: "BY_UUID", uuid: next.question.uuid };
  }

  dictToList(next.giveFeedback).forEach(([questionUuid, isPositive]) => {
    const prevFeedback = prev.giveFeedback[questionUuid];
    if (prevFeedback !== isPositive) {
      diff.newFeedback[questionUuid] = isPositive;
    }
  });

  dictToList(next.toggleBookmarks).forEach(([dataId, bookmarked]) => {
    const prevBookmark = prev.toggleBookmarks[dataId];
    if (prevBookmark !== bookmarked) {
      diff.newBookmarks[dataId] = bookmarked;
    }
  });

  if (next.dismissAlert.feedback !== prev.dismissAlert.feedback) {
    diff.dismissAlerts.feedback = next.dismissAlert.feedback;
  }

  if (next.dismissAlert.bookmark !== prev.dismissAlert.bookmark) {
    diff.dismissAlerts.bookmark = next.dismissAlert.bookmark;
  }

  return diff;
}
