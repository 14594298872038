import type { GaVueComponent } from "@/common/vueUtils";

type GaNumberedListType = {
  items: { title: string; text?: string }[];
};

export function GaNumberedList(props: GaNumberedListType): GaVueComponent {
  return (
    <div>
      {props.items.map((item, index) => (
        <div>
          <p class="mb-1">
            <b>
              {index + 1}. {item.title}
            </b>
          </p>
          {item.text !== undefined ? <p>{item.text}</p> : null}
        </div>
      ))}
    </div>
  );
}
