import type { DeepReadonly } from "vue";
import type { ActionableMiniView } from "@generated/model/actionableMiniView";
import type { AskGetabstractAnalyticsEventVariant, AskGetabstractAnswerContextDoc } from "@newgenerated/shared/schema";
import type { Store } from "@/common/storeUtils";
import { backgroundWorker } from "@/components/ask-getabstract/utils/backgroundWorker";
import { createQuestion, getQuestionAnswers, sendFeedback } from "@generated/api/askGetabstractControllerApi";
import { fetchBookmarkChange } from "@/components/ask-getabstract/utils/requests";
import { delay } from "@utils/asyncUtils";
import { startTimer } from "@/components/ask-getabstract/utils/animationUtils";
import type { Dict } from "@utils/dictUtils";

export const WAITING_INTERVAL_IN_MS = 50;
export const STREAMING_TOKEN_PER_SEC = 10;
export const STREAMING_SPEED_UP_FACTOR = 0.005;

export type Failures = { feedback: boolean; bookmark: boolean };

export type QuestionInteraction =
  | {
      kind: "BY_QUESTION";
      input: string;
      trending?: boolean;
    }
  | {
      kind: "BY_UUID";
      uuid: string;
    }
  | {
      kind: "RELATED";
      input: string;
    };
export type ComponentInteractions = {
  input: string;
  question: QuestionInteraction;
  giveFeedback: Dict<boolean>;
  toggleBookmarks: Dict<boolean>;
  dismissAlert: Failures;
};

export type QuestionAnswer = {
  question: string;
  questionUuid: string;
  detectedLanguageLabel: string | null;
  answer: string;
  contextDocs: DeepReadonly<AskGetabstractAnswerContextDoc[]>;
  relatedActionables: DeepReadonly<ActionableMiniView[]>;
  relatedQuestions: string[];
};

export type InitialUiState = {
  kind: "INITIAL";
};

export type LoadingUiState = {
  kind: "LOADING";
  value: {
    history: DeepReadonly<QuestionAnswer[]>;
    current: DeepReadonly<QuestionAnswer>;
  };
};

export type StreamingUiState = {
  kind: "STREAMING";
  value: {
    streamTokenCount: number;
    history: DeepReadonly<QuestionAnswer[]>;
    current: DeepReadonly<QuestionAnswer>;
  };
};

export type ErrorUiStatus = "ERROR_NO_ANSWER" | "ERROR_GENERAL";
export type CurrentUiStatus = "SUCCESS" | ErrorUiStatus;

export type IdleUiState = {
  kind: "IDLE";
  value: {
    history: DeepReadonly<QuestionAnswer[]>;
    current: DeepReadonly<QuestionAnswer> & {
      status: CurrentUiStatus;
    };
  };
};

export type ComponentUiState = (InitialUiState | LoadingUiState | StreamingUiState | IdleUiState) & {
  feedbackByUuid: DeepReadonly<Dict<boolean>>;
  bookmarksByDataId: DeepReadonly<Dict<boolean>>;
  failures: DeepReadonly<Failures>;
};

export type State = {
  interactions: ComponentInteractions;
  uiState: ComponentUiState;
};

export function resetUiState(): ComponentUiState {
  return {
    kind: "INITIAL",
    feedbackByUuid: {},
    bookmarksByDataId: {},
    failures: { feedback: false, bookmark: false },
  };
}

export function resetInteractions(input: string): ComponentInteractions {
  return {
    input,
    question: { kind: "BY_QUESTION", input: "" },
    giveFeedback: {},
    toggleBookmarks: {},
    dismissAlert: { bookmark: false, feedback: false },
  };
}

export namespace Interactions {
  // User Actions

  export function submitQuestion(store: Store<ComponentInteractions>, question: string, trending?: boolean): void {
    store.sub("input").set(question);
    store.sub("question").set({
      kind: "BY_QUESTION",
      input: question,
      trending,
    });
  }

  export function chooseRelatedQuestion(store: Store<QuestionInteraction>, relatedQuestion: string): void {
    store.set({
      kind: "RELATED",
      input: relatedQuestion,
    });
  }

  export function giveFeedback(store: Store<Dict<boolean>>, questionUuid: string, isPositive: boolean): void {
    const updatedGiveFeedback = { ...store.get() };
    updatedGiveFeedback[questionUuid] = isPositive;
    store.set(updatedGiveFeedback);
  }

  export function toggleBookmark(store: Store<Dict<boolean>>, dataId: number, bookmarked: boolean): void {
    const updatedToggleBookmarks = { ...store.get() };
    updatedToggleBookmarks[dataId] = bookmarked;
    store.set(updatedToggleBookmarks);
  }

  export function dismissAlert(store: Store<Failures>, failureType: keyof Failures): void {
    store.sub(failureType).set(true);
  }

  export function loadByUuid(store: Store<QuestionInteraction>, questionUuid: string): void {
    store.set({
      kind: "BY_UUID",
      uuid: questionUuid,
    });
  }

  // Background Worker Actions
  export function processedBookmark(store: Store<Dict<boolean>>, dataId: string): void {
    const updatedToggleBookmarks = { ...store.get() };
    delete updatedToggleBookmarks[dataId];
    store.set(updatedToggleBookmarks);
  }

  export function processedFeedback(store: Store<Dict<boolean>>, questionUuid: string): void {
    const updatedGiveFeedback = { ...store.get() };
    delete updatedGiveFeedback[questionUuid];
    store.set(updatedGiveFeedback);
  }

  export function processedAlertDismissal(store: Store<Failures>, failureType: keyof Failures): void {
    store.sub(failureType).set(false);
  }
}

export async function runBackgroundWorker(store: Store<State>, analyticsEventVariant: AskGetabstractAnalyticsEventVariant): Promise<void> {
  await backgroundWorker(
    store.sub("uiState"),
    {
      streamingTokensPerSec: STREAMING_TOKEN_PER_SEC,
      streamingSpeedUpFactor: STREAMING_SPEED_UP_FACTOR,
      analyticsEventVariant,
    },
    {
      getInteractions: () => store.sub("interactions").get(),
      processedBookmark: (dataId) => {
        Interactions.processedBookmark(store.sub("interactions").sub("toggleBookmarks"), dataId);
      },
      processedFeedback: (questionUuid) => {
        Interactions.processedFeedback(store.sub("interactions").sub("giveFeedback"), questionUuid);
      },
      processedAlertDismissal: (failureType) => {
        Interactions.processedAlertDismissal(store.sub("interactions").sub("dismissAlert"), failureType);
      },
      initQuestionAnswer: createQuestion,
      getQuestionAnswers: getQuestionAnswers,
      giveFeedback: async (questionUuid, isPositive) => await sendFeedback({ questionUuid, isPositive }),
      toggleBookmark: async (dataId, bookmarked) => {
        await fetchBookmarkChange(dataId, bookmarked);
      },
      delay: () => delay(WAITING_INTERVAL_IN_MS),
      isAborted: () => false,
      startTimer,
    },
  );
}
