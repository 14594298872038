import "@/bootstrap-wrapper/_offcanvas.scss";
import { registerModule } from "@/Registry";
import { assertIsString } from "@utils/assertion";
import Offcanvas from "bootstrap/js/dist/offcanvas";

export function initOffcanvas(e: Element): void {
  const targetSelector = e.getAttribute("data-bs-target");
  assertIsString(targetSelector);
  const target = document.querySelector(targetSelector);
  if (target instanceof HTMLElement) {
    target.addEventListener("show.bs.offcanvas", () => {
      e.classList.add("visible");
      e.ariaExpanded = "true";
    });

    target.addEventListener("hide.bs.offcanvas", () => {
      e.classList.remove("visible");
      e.ariaExpanded = "false";
    });
  }
}

registerModule('[data-bs-toggle="offcanvas"]', initOffcanvas);

export default Offcanvas;
