import { assert, ensureNonNull, hasProp } from "@utils/assertion";
import { registerModule } from "@/Registry";
import { isNonNullObject } from "@utils/objectUtils";

type CourseProgress = {
  courseCompleted: boolean;
  courseProgress: number;
};

const parseCourseProgress = (data: unknown): CourseProgress => {
  assert(isNonNullObject(data) && hasProp(data, "courseCompleted"));
  assert(typeof data.courseCompleted === "boolean");
  assert(hasProp(data, "courseProgress"));
  assert(typeof data.courseProgress === "number");
  return {
    courseCompleted: ensureNonNull<boolean>(data.courseCompleted, "courseCompleted is required"),
    courseProgress: ensureNonNull<number>(data.courseProgress, "courseProgress is required"),
  };
};

const updateDomInitial = (actionableSteps: HTMLElement): void => {
  // Show dummy Do-step, hide real step
  ensureNonNull(actionableSteps.querySelector<HTMLElement>('[data-ga-actionable-step-inactive="do"]')).hidden = false;
  ensureNonNull(actionableSteps.querySelector<HTMLElement>('[data-ga-actionable-step-active="do"]')).hidden = true;

  // On Know-step show no icons
  ensureNonNull(actionableSteps.querySelector<HTMLElement>('[data-ga-actionable-step-active="know"] [data-ga-actionable-step-icon-started]')).hidden = true;
  ensureNonNull(actionableSteps.querySelector<HTMLElement>('[data-ga-actionable-step-active="know"] [data-ga-actionable-step-icon-finished]')).hidden = true;

  // On Know-step show no border
  const knowStepActive = ensureNonNull(actionableSteps.querySelector<HTMLElement>('[data-ga-actionable-step-active="know"]'));
  knowStepActive.classList.remove("actionable-step--started");
  knowStepActive.classList.remove("actionable-step--completed");

  // On Know-step set button text
  const courseButton = ensureNonNull(actionableSteps.querySelector<HTMLElement>("[data-ga-actionable-course-button]"));
  courseButton.textContent = courseButton.dataset.gaCourseStartText ?? "";
  courseButton.classList.add("btn-primary");
  courseButton.classList.remove("btn-success");

  // Collapse step 2, expand step 1
  ensureNonNull(actionableSteps.querySelector<HTMLElement>("#collapse1")).classList.add("show");
  ensureNonNull(actionableSteps.querySelector<HTMLElement>("#collapse2")).classList.remove("show");
};

const updateDomStarted = (actionableSteps: HTMLElement): void => {
  // Show dummy Do-step, hide real step
  ensureNonNull(actionableSteps.querySelector<HTMLElement>('[data-ga-actionable-step-inactive="do"]')).hidden = false;
  ensureNonNull(actionableSteps.querySelector<HTMLElement>('[data-ga-actionable-step-active="do"]')).hidden = true;

  // On Know-step show course-started-icon, hide course-finished-icon
  ensureNonNull(actionableSteps.querySelector<HTMLElement>('[data-ga-actionable-step-active="know"] [data-ga-actionable-step-icon-started]')).hidden = false;
  ensureNonNull(actionableSteps.querySelector<HTMLElement>('[data-ga-actionable-step-active="know"] [data-ga-actionable-step-icon-finished]')).hidden = true;

  // On Know-step show course-started-border, hide course-completed-border
  const knowActiveSteps = ensureNonNull(actionableSteps.querySelector<HTMLElement>('[data-ga-actionable-step-active="know"]'));
  knowActiveSteps.classList.remove("actionable-step--completed");
  knowActiveSteps.classList.add("actionable-step--started");

  // On Know-step set button text
  const courseButton = ensureNonNull(actionableSteps.querySelector<HTMLElement>("[data-ga-actionable-course-button]"));
  courseButton.textContent = courseButton.dataset.gaCourseContinueText ?? "";

  // Collapse step 2, expand step 1
  ensureNonNull(actionableSteps.querySelector<HTMLElement>("#collapse1")).classList.add("show");
  ensureNonNull(actionableSteps.querySelector<HTMLElement>("#collapse2")).classList.remove("show");
};

const updateDomCompleted = (actionableSteps: HTMLElement): void => {
  // Show real Do-step, hide dummy step
  ensureNonNull(actionableSteps.querySelector<HTMLElement>('[data-ga-actionable-step-inactive="do"]')).hidden = true;
  ensureNonNull(actionableSteps.querySelector<HTMLElement>('[data-ga-actionable-step-active="do"]')).hidden = false;

  // On Know-step show course-finished-icon, hide course-started-icon
  ensureNonNull(actionableSteps.querySelector<HTMLElement>('[data-ga-actionable-step-active="know"] [data-ga-actionable-step-icon-started]')).hidden = true;
  ensureNonNull(actionableSteps.querySelector<HTMLElement>('[data-ga-actionable-step-active="know"] [data-ga-actionable-step-icon-finished]')).hidden = false;

  // On Know-step show course-finished-border, hide course-started-border
  const knowStepActive = ensureNonNull(actionableSteps.querySelector<HTMLElement>('[data-ga-actionable-step-active="know"]'));
  knowStepActive.classList.remove("actionable-step--started");
  knowStepActive.classList.add("actionable-step--completed");

  // On Know-step set button text
  const courseButton = ensureNonNull(actionableSteps.querySelector<HTMLElement>("[data-ga-actionable-course-button]"));
  courseButton.textContent = courseButton.dataset.gaCourseRestartText ?? "";
  courseButton.classList.remove("btn-primary");
  courseButton.classList.add("btn-success");

  // Collapse step 1, expand step 2
  ensureNonNull(actionableSteps.querySelector<HTMLElement>("#collapse1")).classList.remove("show");
  ensureNonNull(actionableSteps.querySelector<HTMLElement>("#collapse2")).classList.add("show");
};

export async function updateStepsByCourseProgress(rootProgressElement: Element): Promise<void> {
  assert(rootProgressElement instanceof HTMLElement);
  const endPointUrl = rootProgressElement.dataset.gaActionableProgress;
  assert(endPointUrl !== undefined);
  const actionableSteps = rootProgressElement.querySelector("[data-ga-actionable-steps]");
  assert(actionableSteps instanceof HTMLElement);

  try {
    const response = await fetch(endPointUrl, {
      method: "GET",
    });
    const courseProgress = parseCourseProgress(await response.json());
    if (courseProgress.courseCompleted) {
      updateDomCompleted(actionableSteps);
    } else if (courseProgress.courseProgress > 0) {
      updateDomStarted(actionableSteps);
    } else {
      updateDomInitial(actionableSteps);
    }
  } catch (error: unknown) {
    console.log(error);
  }
}

/**
 * Register event to update the actionable page with the current course progress.
 * TODO: Remove when actionable.js is converted to typescript.
 */
export function registerUpdateEvent(rootProgressElement: Element): void {
  rootProgressElement.addEventListener("ga.actionable.updateCourse", () => updateStepsByCourseProgress(rootProgressElement));
}

registerModule("[data-ga-actionable-progress]", registerUpdateEvent);
