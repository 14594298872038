import { registerModule } from "@/Registry";
import { getCookie, setCookie } from "@utils/cookieUtils";
import { toggleTooltips } from "@/components/general/_tooltip";

const COOKIE_NAME_LAYOUT_COLLAPSED = "layout-collapsed";

function update(isCollapsed: boolean, sidebarElement: Element, collapseToggleIcon: Element): void {
  setCookie(COOKIE_NAME_LAYOUT_COLLAPSED, isCollapsed.toString());
  sidebarElement.setAttribute("data-ga-layout-collapsed", isCollapsed.toString());
  toggleTooltips(sidebarElement, isCollapsed);
  if (isCollapsed) {
    collapseToggleIcon.classList.add("ico-chevron-right");
    collapseToggleIcon.classList.remove("ico-chevron-left");
  } else {
    collapseToggleIcon.classList.remove("ico-chevron-right");
    collapseToggleIcon.classList.add("ico-chevron-left");
  }
}

export function initLayoutCollapse(element: Element): void {
  const collapseNavButtons = element.querySelectorAll("[data-ga-layout-collapse-button]");

  const collapseToggle = element.querySelector("[data-ga-layout-collapse-toggle]");
  if (collapseToggle === null) {
    return;
  }

  const collapseToggleIcon = collapseToggle.querySelector("[data-ga-layout-collapse-icon]");
  if (collapseToggleIcon === null) {
    return;
  }

  let isCollapsed = getCookie(COOKIE_NAME_LAYOUT_COLLAPSED) === "true";
  toggleTooltips(element, isCollapsed);

  [...collapseNavButtons, collapseToggle].forEach((item) => {
    item.addEventListener("click", () => {
      isCollapsed = !isCollapsed;
      update(isCollapsed, element, collapseToggleIcon);
    });
  });
}

registerModule("[data-ga-layout-collapsed]", initLayoutCollapse);
