import type { AbortableFunction } from "@utils/asyncUtils";

export type AnimationTimer = {
  getTimePassedInMs: () => number;
};

export function startTimer(): AnimationTimer {
  const start = Date.now();
  return {
    getTimePassedInMs: () => Date.now() - start,
  };
}

/*
 * Streaming animation
 */

export function createRunStreamingAnimationEndless(
  props: {
    streamingTokensPerSec: number;
  },
  actions: {
    startTimer: () => AnimationTimer;
    delay: () => Promise<void>;
    getMaxTokenAvailable: () => number;
    updateTokenCount: (tokenCount: number) => void;
  },
): AbortableFunction<{ kind: "CANCELLED" }> {
  return async (signal: AbortSignal) => {
    const timer = actions.startTimer();

    while (!signal.aborted) {
      const secondsSinceStart = timer.getTimePassedInMs() / 1000;
      const calculatedTokenCount = secondsSinceStart * props.streamingTokensPerSec;
      const maxTokenAvailable = actions.getMaxTokenAvailable();

      actions.updateTokenCount(Math.min(calculatedTokenCount, maxTokenAvailable));

      await actions.delay();
    }

    return { kind: "CANCELLED" };
  };
}

export function createRunStreamingAnimationUntilMaxReached(
  props: {
    streamingTokensPerSec: number;
    streamingSpeedUpFactor: number;
    alreadyAnimatedTokens: number;
  },
  actions: {
    startTimer: () => AnimationTimer;
    delay: () => Promise<void>;
    getMaxTokenAvailable: () => number;
    updateTokenCount: (tokenCount: number) => void;
  },
): AbortableFunction<{ kind: "REACHED_END" | "CANCELLED" }> {
  return async (signal: AbortSignal) => {
    const timer = actions.startTimer();

    while (!signal.aborted) {
      const secondsSinceStart = timer.getTimePassedInMs() / 1000;
      const speedUp = secondsSinceStart ** props.streamingTokensPerSec * props.streamingSpeedUpFactor; // We have the whole answer at this point in time, therefore we speed up the reveal.
      const calculatedTokenCount = secondsSinceStart * props.streamingTokensPerSec + props.alreadyAnimatedTokens + speedUp;
      const maxTokenAvailable = actions.getMaxTokenAvailable();

      actions.updateTokenCount(Math.min(calculatedTokenCount, maxTokenAvailable));

      if (calculatedTokenCount >= maxTokenAvailable) {
        return { kind: "REACHED_END" };
      }

      await actions.delay();
    }

    return { kind: "CANCELLED" };
  };
}
