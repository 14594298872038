import type { GaVueComponent } from "@/common/vueUtils";
import { Answer } from "@/components/ask-getabstract/uiFragments/Answer";
import { Feedback } from "@/components/ask-getabstract/uiFragments/Feedback";
import { References } from "@/components/ask-getabstract/uiFragments/References";
import type { PropType } from "vue";
import { type DeepReadonly, defineComponent, ref } from "vue";
import { GaCollapse } from "@/components/general/GaCollapse";
import { RelatedActionables } from "@/components/ask-getabstract/uiFragments/RelatedActionables";
import type { ComponentInteractions, ComponentUiState, QuestionAnswer } from "@/components/ask-getabstract/utils/store";
import type { Store } from "@/common/storeUtils";
import type { Dict } from "@utils/dictUtils";

function AnswerEntry(props: {
  bookmarksByDataId: DeepReadonly<Dict<boolean>>;
  interactionsStore: Store<ComponentInteractions>;
  entry: DeepReadonly<QuestionAnswer>;
  toggleHistoryEntry: (questionUuid: string, show: boolean) => void;
}): GaVueComponent | null {
  return (
    <div class="d-grid gap-4">
      <Answer answer={props.entry.answer} contextDocs={props.entry.contextDocs} questionUuid={props.entry.questionUuid} />
      <Feedback feedbackStore={props.interactionsStore.sub("giveFeedback")} question={props.entry.question} questionUuid={props.entry.questionUuid} border={true} />
      <References
        bookmarksByDataId={props.bookmarksByDataId}
        bookmarkInteractionsStore={props.interactionsStore.sub("toggleBookmarks")}
        contextDocs={props.entry.contextDocs}
        answer={props.entry.answer}
        questionUuid={props.entry.questionUuid}
        border={true}
      />
      <RelatedActionables relatedActionables={props.entry.relatedActionables} questionUuid={props.entry.questionUuid} border={true} />
    </div>
  );
}

export const HistoryEntry = defineComponent({
  props: {
    interactionsStore: {
      type: Object as PropType<Store<ComponentInteractions>>,
      required: true,
    },
    entry: {
      type: Object as PropType<DeepReadonly<QuestionAnswer>>,
      required: true,
    },
    bookmarksByDataId: {
      type: Object as PropType<DeepReadonly<Dict<boolean>>>,
      required: true,
    },
  },
  setup(props) {
    const visible = ref(false);

    return () => (
      <GaCollapse title={props.entry.question} visible={visible.value} onUpdateVisible={(show) => (visible.value = show)} id={props.entry.questionUuid}>
        <AnswerEntry bookmarksByDataId={props.bookmarksByDataId} interactionsStore={props.interactionsStore} entry={props.entry} toggleHistoryEntry={() => (visible.value = !visible.value)} />
      </GaCollapse>
    );
  },
});

export function History(props: { interactionsStore: Store<ComponentInteractions>; uiState: DeepReadonly<ComponentUiState> }): GaVueComponent | null {
  if (props.uiState.kind === "INITIAL" || props.uiState.value.history.length === 0) {
    return null;
  }

  return (
    <div class="d-grid gap-3">
      {props.uiState.value.history.map((qa) => (
        <HistoryEntry bookmarksByDataId={props.uiState.bookmarksByDataId} interactionsStore={props.interactionsStore} entry={qa} key={qa.questionUuid} />
      ))}
    </div>
  );
}
