/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// @ts-ignore
import { type VerifaiOverviewItem, parseVerifaiOverviewItem, renderVerifaiOverviewItem } from './verifaiOverviewItem';
// @ts-ignore
import { Language, DateTime } from "@utils/type/type";
// @ts-ignore
import { type LocalDate, LocalDateUtils } from "@utils/type/LocalDate";
// @ts-ignore
import { assertIsDefined, ensureNonNull } from "@utils/assertion";

export type VerifaiCreatorView = {
    authorName: string;
    claimableItems: Array<VerifaiOverviewItem>;
    claimedItems: Array<VerifaiOverviewItem>;
}

export const parseVerifaiCreatorView = (data: any): VerifaiCreatorView => {
    return {
        authorName: ensureNonNull<string>(data.authorName, "authorName is required"),
        claimableItems: ensureNonNull<Array<VerifaiOverviewItem>>(data.claimableItems, "claimableItems is required").map((value: any) => parseVerifaiOverviewItem(value)),
        claimedItems: ensureNonNull<Array<VerifaiOverviewItem>>(data.claimedItems, "claimedItems is required").map((value: any) => parseVerifaiOverviewItem(value)),
    }
}

export const renderVerifaiCreatorView = (data: VerifaiCreatorView): any => {
    return {
        authorName: data.authorName,
        claimableItems: ensureNonNull<Array<VerifaiOverviewItem>>(data.claimableItems, "claimableItems is required").map((value: any) => renderVerifaiOverviewItem(value)),
        claimedItems: ensureNonNull<Array<VerifaiOverviewItem>>(data.claimedItems, "claimedItems is required").map((value: any) => renderVerifaiOverviewItem(value)),
    }
}


