import { registerModule } from "@/Registry";

export const OPEN_CERTIFICATE_POPUP_EVENT = "ga.actionable.certificatePopupOpen";
export const OPEN_CERTIFICATE_BUTTON_ELEMENTSELECTOR = "[data-ga-actionable-openCertificatePopup]";

function registerEvent(scope: Element): void {
  scope.addEventListener("click", () => {
    const element = document.querySelector("[data-ga-actionable-step-active='reflect']");
    const steps = document.querySelector("#actionable-steps");
    const step3 = document.querySelector("#collapse3");
    if (steps != null && !steps.classList.contains("show")) {
      steps.classList.add("show");
    }
    if (step3 != null && !step3.classList.contains("show")) {
      step3.classList.add("show");
    }
    if (element !== null) {
      element.scrollIntoView();
    }
    const event = new Event(OPEN_CERTIFICATE_POPUP_EVENT);
    scope.dispatchEvent(event);
  });
}

registerModule(OPEN_CERTIFICATE_BUTTON_ELEMENTSELECTOR, registerEvent);
