import { defineComponent, ref } from "vue";
import "@/components/ask-getabstract/AskGetabstractIframe.scss";
import { GaFormFieldInputText } from "@/components/form/GaFormFieldInputText";
import { GaButton } from "@/components/general/GaButton";
import { useI18n } from "@/i18n/i18nSetup";

export const AskGetabstractIframe = defineComponent({
  setup() {
    const { t } = useI18n();
    const questionInput = ref<string>("");
    const updateQuestion = (input: string): void => {
      questionInput.value = input;
    };
    const submitForm = (e: Event): void => {
      e.preventDefault();
      const baseUrl = new URL("ask-getabstract", window.location.origin);
      baseUrl.search = window.location.search;
      if (questionInput.value.length > 0) {
        baseUrl.searchParams.append("question", questionInput.value);
      }
      window.open(baseUrl, "_blank");
    };
    return () => (
      <div class="getabstract-ai-iframe card">
        <div class="getabstract-ai-iframe__header" onClick={submitForm}>
          <img class="getabstract-ai-iframe__header__img" src="/www/images/ai/ai_logo.svg" alt="" />
          <div class="getabstract-ai-iframe__header__ai">
            <i class="ico-rocket"></i>
            <span>{t("search:askGa.iframe.title").toUpperCase()}</span>
          </div>
          <h2 class="getabstract-ai-iframe__header__title">{t("search:askGa.productTitle")}</h2>
          <p class="getabstract-ai-iframe__description">{t("search:askGa.iframe.description")}</p>
        </div>
        <div class="getabstract-ai-iframe__body">
          <form action="" class="getabstract-ai-iframe__form" onSubmit={submitForm}>
            <GaFormFieldInputText name="question" aria-labelledby="askGetabstractBtn" placeholder={t("search:askGa.askInputPlaceholder")} value={questionInput.value} onUpdateValue={updateQuestion} />
            <GaButton type="submit" id="askGetabstractBtn">
              {t("search:askGa.askButtonLabel")}
            </GaButton>
          </form>
          <p class="getabstract-ai-iframe__explanation small">
            <i class="ico-info-circle"></i>
            <span class="getabstract-ai-iframe__explanation__info">{t("search:askGa.iframe.info")} </span>
            <span>{t("search:askGa.iframe.question")}</span>
          </p>
        </div>
      </div>
    );
  },
});
