function pad(num: number): string {
  return (num < 10 ? "0" : "") + num;
}

export function toIsoString(date: Date, withTimeZone: boolean = true): string {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";

  const dateTimeString = date.getFullYear() + "-" + pad(date.getMonth() + 1) + "-" + pad(date.getDate()) + "T" + pad(date.getHours()) + ":" + pad(date.getMinutes()) + ":" + pad(date.getSeconds());
  return dateTimeString + (withTimeZone ? dif + pad(Math.floor(Math.abs(tzo) / 60)) + ":" + pad(Math.abs(tzo) % 60) : "");
}
