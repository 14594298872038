import { toIsoString } from "@utils/vue-migration/api/dateUtils";

export type ShareLink = {
  href: string;
  title: string;
};

function googlifyDate(value: string): string {
  return value.replace(/-/g, "").replace(/:/g, "");
}

export function getShareLinks(dateTitle: string, dateDescription: string): ShareLink[] {
  const currentDate = new Date();
  const dateFrom = new Date(currentDate.getTime() + (30 - (currentDate.getMinutes() % 30)) * 60000);
  const dateTo = new Date(dateFrom.getTime() + 60 * 60000);
  const currentDateFormatted = toIsoString(dateFrom, false);
  const dateToFormatted = toIsoString(dateTo, false);
  const title = encodeURIComponent(dateTitle);
  const description = encodeURIComponent(dateDescription);
  const googleLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${googlifyDate(currentDateFormatted)}/${googlifyDate(dateToFormatted)}&details=${description}&location=&trp=false`;
  const outlookLink = `https://outlook.office.com/calendar/0/action/compose?allday=false&body=${description}&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${currentDateFormatted}&enddt=${dateToFormatted}&subject=${title}`;
  const teamsLink = `https://teams.microsoft.com/l/meeting/new?subject=${title}&content=${description}`;
  return [
    { href: outlookLink, title: "Outlook" },
    { href: googleLink, title: "Google" },
    { href: teamsLink, title: "Teams" },
  ];
}
