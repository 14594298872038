import { useI18n } from "@/i18n/i18nSetup";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaButton } from "@/components/general/GaButton";

export function TrendingQuestions(props: { chooseTrendingQuestion: (question: string) => void }): GaVueComponent {
  const { t } = useI18n();
  const questions = [t("search:askGa.trendingQuestions.question01"), t("search:askGa.trendingQuestions.question02"), t("search:askGa.trendingQuestions.question03"), t("search:askGa.trendingQuestions.question04")];

  return (
    <div>
      <hr class="mb-5" />
      <h5 class="d-inline pt-2">
        <i class="ico-rocket"></i>
        <span vHtml={t("search:askGa.trendingQuestions.description")} style="font-weight: normal;" />
      </h5>
      <div class="getabstract-ai__suggestions">
        <ul>
          {questions.map((q) => (
            <li>
              <GaButton variant="link-slim" onClick={() => props.chooseTrendingQuestion(q)}>
                <span>
                  <i class="ico-plus-circle text-primary"></i> {q}
                </span>
              </GaButton>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
