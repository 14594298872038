/**
 * Getabstract API
 * This is a draft
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { getHttpClient } from "@utils/httpClient";

/* tslint:disable:no-unused-locals */
// @ts-ignore
import type { Page } from "@utils/type/type";


/**
* 
* @summary Sets the experience feedback score for the actionable and current user.
* @param actionableId 
* @param score 
*/
export async function setExperienceFeedback(actionableId: number, score: number): Promise<void> {
        return getHttpClient().request({
            url: '/gaapi/actionables/{actionableId}/experience-feedback'
                .replace('{' + 'actionableId' + '}', encodeURIComponent(String(actionableId))),
            method: 'POST',
            params: { score }
        });
}
/**
* 
* @summary Track start of peer insight step
* @param actionableId 
*/
export async function trackPeerInsightStarted(actionableId: number): Promise<void> {
        return getHttpClient().request({
            url: '/gaapi/actionables/{actionableId}/peerinsight-started'
                .replace('{' + 'actionableId' + '}', encodeURIComponent(String(actionableId))),
            method: 'POST'
        });
}
/**
* 
* @summary Track video progress for the pro talk video.
* @param actionableId 
* @param progress 
*/
export async function trackProTalkVideoProgress(actionableId: number, progress: number): Promise<void> {
        return getHttpClient().request({
            url: '/gaapi/actionables/{actionableId}/pro-talk-video-progress'
                .replace('{' + 'actionableId' + '}', encodeURIComponent(String(actionableId))),
            method: 'POST',
            params: { progress }
        });
}
/**
* 
* @summary Track share event for actionable.
* @param actionableId 
*/
export async function trackShareLinkCopy(actionableId: number): Promise<void> {
        return getHttpClient().request({
            url: '/gaapi/actionables/{actionableId}/share-link-copy'
                .replace('{' + 'actionableId' + '}', encodeURIComponent(String(actionableId))),
            method: 'POST'
        });
}
/**
* 
* @summary Track video progress for the step2 video.
* @param actionableId 
* @param progress 
*/
export async function trackStep2VideoProgress(actionableId: number, progress: number): Promise<void> {
        return getHttpClient().request({
            url: '/gaapi/actionables/{actionableId}/step2-video-progress'
                .replace('{' + 'actionableId' + '}', encodeURIComponent(String(actionableId))),
            method: 'POST',
            params: { progress }
        });
}

