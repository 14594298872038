import type { DeepReadonly } from "vue";
import type { GaVueComponent } from "@/common/vueUtils";
import { GaTooltip } from "@/components/general/GaTooltip";
import { getUsedReferencesByDataId, parseReferences, type ReferencesByDataId } from "@/components/ask-getabstract/utils/answerUtils";
import { GaMarkdownContent, type MarkdownNode, useSafeMarkdownRenderer } from "@/components/general/GaMarkdownContent";
import type { IntermediateRenderer } from "@/common/rendererUtils";
import type { AskGetabstractAnswerContextDoc } from "@newgenerated/shared/schema";

export function createReferencesRenderer(usedReferencesByDataId: ReferencesByDataId): IntermediateRenderer<MarkdownNode> {
  return (node) => {
    if (node.type !== "text") {
      return "next-renderer";
    }
    return (
      <>
        {parseReferences(node.value, usedReferencesByDataId).map((node) => {
          switch (node.kind) {
            case "text":
              return <>{node.text}</>;
            case "reference":
              return (
                <GaTooltip title={node.reference.title}>
                  [
                  <a class="getabstract-ai__answer-reference" href={node.reference.urls.reference} target="_blank">
                    {node.reference.referenceNumber}
                  </a>
                  ]
                </GaTooltip>
              );
          }
        })}
      </>
    );
  };
}

export function Answer(props: { answer: string; contextDocs: DeepReadonly<AskGetabstractAnswerContextDoc[]>; questionUuid: string }): GaVueComponent {
  const usedReferencesByDataId = getUsedReferencesByDataId(props.answer, props.contextDocs);
  return (
    <div class="getabstract-ai__answer">
      <GaMarkdownContent
        input={props.answer}
        defaultRenderer={useSafeMarkdownRenderer(() => (
          <></>
        ))}
        intermediateRenderers={[createReferencesRenderer(usedReferencesByDataId)]}
      />
    </div>
  );
}

export function AnswerCard(props: { answer: string; contextDocs: DeepReadonly<AskGetabstractAnswerContextDoc[]>; questionUuid: string }): GaVueComponent {
  return (
    <div class="getabstract-ai__answer-card">
      <Answer answer={props.answer} contextDocs={props.contextDocs} questionUuid={props.questionUuid} />
    </div>
  );
}

export function AnswerCardPlaceholder(): GaVueComponent {
  return (
    <div class="getabstract-ai__answer-card">
      <div class="getabstract-ai__answer">
        <p class="placeholder-wave">
          <span class="placeholder col-7 me-3"></span>
          <span class="placeholder col-4 me-3"></span>
          <span class="placeholder col-4 me-3"></span>
          <span class="placeholder col-6 me-3"></span>
          <span class="placeholder col-8 me-3"></span>
        </p>
      </div>
    </div>
  );
}
