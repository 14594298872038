import { defineComponent, ref } from "vue";
import { GaProgressCircle } from "@/components/general/GaProgressCircle";
import { GaButton } from "@/components/general/GaButton";

export const GaProgressCircleDemo = defineComponent({
  setup: () => {
    const progress = ref(0);
    const progress2 = ref(0);

    function changeProgress(value: number): void {
      progress.value = progress.value + value;
    }

    function changeProgress2(value: number): void {
      progress2.value = progress2.value + value;
    }

    return () => (
      <div>
        <h1 class="mb-4">Progress circles</h1>
        <h2 class="mb-3">All the colors</h2>
        <div class="mb-5 d-flex gap-2">
          <GaProgressCircle progress={30}></GaProgressCircle>
          <GaProgressCircle blankCircleColor="var(--ga-gray-200)" progressCircleColor="var(--ga-purple-600)" progress={50}></GaProgressCircle>
          <GaProgressCircle blankCircleColor="var(--ga-neutral-cool-900)" progressCircleColor="var(--ga-orange-900)" progress={50}></GaProgressCircle>
          <GaProgressCircle blankCircleColor="#F88D8B" progressCircleColor="var(--ga-primary-500)" progress={70}></GaProgressCircle>
          <GaProgressCircle blankCircleColor="#F1F7ED" progressCircleColor="var(--ga-success-500)" progress={95}></GaProgressCircle>
        </div>
        <h2 class="mb-3">Fancy animations</h2>
        <div class="mb-5">
          <div class="d-flex gap-2 mb-3">
            <GaProgressCircle progress={progress.value}></GaProgressCircle>
            <GaProgressCircle blankCircleColor="var(--ga-gray-200)" progressCircleColor="var(--ga-purple-600)" progress={progress.value}></GaProgressCircle>
            <GaProgressCircle blankCircleColor="var(--ga-neutral-cool-900)" progressCircleColor="var(--ga-orange-900)" progress={progress.value}></GaProgressCircle>
            <GaProgressCircle blankCircleColor="#F88D8B" progressCircleColor="var(--ga-primary-500)" progress={progress.value}></GaProgressCircle>
            <GaProgressCircle blankCircleColor="#F1F7ED" progressCircleColor="var(--ga-success-500)" progress={progress.value}></GaProgressCircle>
          </div>
          <GaButton onClick={() => changeProgress(10)}>Add 10% Progress</GaButton>
          &nbsp;
          <GaButton onClick={() => changeProgress(-10)}>Remove 10% Progress</GaButton>
        </div>
        <h2 class="mb-3">With a icon to start at zero progress</h2>
        <div class="mb-5">
          <div class="d-flex gap-2 mb-3">
            <GaProgressCircle noProgressIcon="ico-rocket" progress={progress2.value}></GaProgressCircle>
            <GaProgressCircle noProgressIcon="ico-play-fill" blankCircleColor="var(--ga-gray-200)" progressCircleColor="var(--ga-purple-600)" progress={progress2.value}></GaProgressCircle>
            <GaProgressCircle noProgressIcon="ico-plus-circle" blankCircleColor="var(--ga-neutral-cool-900)" progressCircleColor="var(--ga-orange-900)" progress={progress2.value}></GaProgressCircle>
            <GaProgressCircle noProgressIcon="ico-compass" blankCircleColor="#F88D8B" progressCircleColor="var(--ga-primary-500)" progress={progress2.value}></GaProgressCircle>
            <GaProgressCircle noProgressIcon="ico-mobile" blankCircleColor="#F1F7ED" progressCircleColor="var(--ga-success-500)" progress={progress2.value}></GaProgressCircle>
          </div>
          <GaButton onClick={() => changeProgress2(15)}>Add 15% Progress</GaButton>
          &nbsp;
          <GaButton onClick={() => changeProgress2(-15)}>Remove 15% Progress</GaButton>
        </div>
      </div>
    );
  },
});
