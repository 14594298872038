import { registerModule } from "@/Registry";

export const ACTIONABLE_WIDTH_TOGGLE_EVENT = "ga.actionable.width.toggle";
export const ACTIONABLE_WIDTH_TOGGLE_SELECTOR = "[data-ga-actionable-step-width]";

function init(element: Element): void {
  element.addEventListener(ACTIONABLE_WIDTH_TOGGLE_EVENT, () => {
    element.classList.toggle("actionable-step-container--wide");
  });
}

registerModule(ACTIONABLE_WIDTH_TOGGLE_SELECTOR, init);
